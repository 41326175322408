import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import DbTasks from '../../db/DbTasks';
import { getBeraterId } from '../../helpers/HelperFunctions';
import moment from 'moment-with-locales-es6';
import { Grid, makeStyles } from '@material-ui/core';
import CardContainerDates from '../Reusable/CardContainerDates';
import { FaClock } from 'react-icons/fa';
import { FaHome } from 'react-icons/fa'
import BackdropSpinner from '../Reusable/BackdropSpinner';
import {getDataFromDatabase} from '../../db/api';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(2),
    }
}));


function Arbeitszeiten() {
    const classes = useStyles();
    const [localState, setLocalState] = useState({
        workingTimes: [],
        showLoader: true,
    })
    //const [workingTimes, setWorkingTimes] = useState([]);

    useEffect(() => {
        let beraterId = getBeraterId();
        getDataFromDatabase(`api/FirmaInfo/ArbeitszeitInfo?id=${beraterId}`, {  }, { method: "GET" })
            .then(res => {
                //console.log("rest",res);
                setLocalState({...localState, workingTimes: res, showLoader: false})
                //console.log("Working times :", res);
            }).catch(() => {

            })
        return () => {
            console.log("Running cleanup function !");
        }
    }, [])
    return (
        <div className={classes.root}>
            <Grid container spacing={1} alignContent='center' justify='center'>            
            {localState.workingTimes.length > 0 && localState.workingTimes.map((el, index) => {
                return (
                //<li>{moment(el.date).format("DD.MM.YYYY")} {el.von} {el.bis}</li>
                <Grid item lg={4} md={6} sm={8} xs={12} key={index} >
                <CardContainerDates
                    key={index}
                    title={moment(el.date).locale('de').format("dddd")}
                    number={`${el.von} - ${el.bis}`}
                    subtitle={`${moment(el.date).format("DD.MM.YYYY")}`}
                    class={el.isHoliday ? "holiday" : "white"}
                    mainIcon={el.isHoliday ? <FaHome size={"2em"} color="white" /> : <FaClock size={"2em"} color="grey" />}
                />
            </Grid>
                )
            })}
            {localState.workingTimes.length === 0 && 
            <Grid item lg={4} md={6} sm={8} xs={12} >
                <p>Arbeitszeiten sind in Suite nicht konfiguriert.</p>
            </Grid>}
            </Grid>
            <BackdropSpinner showLoading={localState.showLoader} />
        </div>
    )
}

export default withRouter(Arbeitszeiten);