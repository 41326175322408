import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CustomTextField from '../Reusable/TextFields';
// import style from '../assets/styles/Login.module.css';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import clsx from 'clsx';
import { getBeraterId } from '../../helpers/HelperFunctions';
import DbTasks from "../../db/DbTasks";
import Notifications from '../Reusable/Notifications';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import BackdropSpinner from '../Reusable/BackdropSpinner'

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '70vh',
        maxWidth: '100%',
        overflowX: 'hidden',
        margin: theme.spacing(0),

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

    },
    elementSpacing: {
        margin: theme.spacing(1)
    },
    button: {
        backgroundColor: "#166abd",
        '&:hover': {
            backgroundColor: "#166abd",
        },
        color: "#FFFFFF",
        fontSize: "22px",
        margin: theme.spacing(1)
    },
    wrapper: {
        margin: theme.spacing(0),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: "#3cb498",
        '&:hover': {
            backgroundColor: "#0eb58e",
        },
        color: "#FFFFFF",
        fontSize: "22px",
        margin: theme.spacing(1)
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: 'white',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    logo:{
        maxHeight: '100%',
        maxWidth: '100%',
    },
    incorrectCredentials:{
        color: 'red',
    }
}));

const ChangePassword = (props) => {
    const classes = useStyles();
    const [local, setLocal] = React.useState({
        success: false,
        loading: false,
        error: false,
        errorType:0
    })
    let [oldPassword, setOldPassword] = useState("");
    let [showOldPassword, setShowOldPassword] = useState(false);
    let [newPassword, setNewPassword] = useState("");
    let [showNewPassword, setShowNewPassword] = useState(false);
    let [newPasswordAgain, setNewPasswordAgain] = useState("");
    let [showNewPasswordAgain, setShowNewPasswordAgain] = useState(false);
    const buttonClassname = clsx({
        [classes.buttonSuccess]: local.success,
        [classes.button]: !local.success
    });

    const handlePasswordChange = () =>{
      setLocal({...local, loading:true});
      let db = new DbTasks();
      let beraterId = getBeraterId();
      //console.log("oldpassoword", oldPassword);
      db.getDataFromDb(`api/FirmaInfo/CheckIfOldPasswordIsCorrect?id=${beraterId}&oldPassword=${encodeURIComponent(oldPassword)}`, {}, { method: "GET" })
      .then(res => {
        if (res == false)
        {
          setLocal({...local,loading:false,error:true, errorType:1});
          return;
        }
        else
        {
          setLocal({...local, error:false, loading:false, errorType:1});
          if(newPassword != '' && newPasswordAgain != '' && newPassword == newPasswordAgain){
            setLocal({...local, error:false, loading:true, errorType:0})
            db.getDataFromDb(`api/FirmaInfo/ChangePassword?id=${beraterId}&newPassword=${encodeURIComponent(newPassword)}`, {}, { method: "GET" })
           .then(res =>{
            //console.log("res pormjena passworda",res);
            if(res == true)
            {
              setLocal({...local, loading:false, error:false});
              Notifications('success',"Passwort wurde erfolgreich geändert");
            }
            else
            {
              setLocal({...local, loading:false});
              Notifications('warning',"Ändern des Passworts ging schief");
            }
           })
          }else{
            setLocal({...local, error:true,loading:false,errorType:0})
          }
        }
      });
    }

    return (
        <div className={classes.root}>
            <Grid container  direction="column" spacing={0} alignContent='center' justify='center' alignItems='center'>
                <Grid item lg={4} md={6} sm={12} xs={10}>
                    {/* <img src={Logo} className={style.logo} alt="Logo" /> */}
                    {/* <div style={{ height: '100%' }}> */}
                        <div style={{ textAlign: 'center' }}>
                            <img src="/mitarbeiterLogo.png" alt="Window-ID" className={classes.logo} />
                        </div>
                    {/* </div> */}
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={10}>
                    <CustomTextField
                        onChange={(e) => setOldPassword(oldPassword = e.currentTarget.value)}
                        value={oldPassword}
                        id="oldpassword"
                        type={showOldPassword ? 'text' : 'password'}
                        label="Jetziges Passwort"
                        variant="outlined"
                        fullWidth={true}
                        class={classes.elementSpacing}
                        InputProps={{
                          endAdornment: <InputAdornment position="start">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={()=>setShowOldPassword(true)}
                              onMouseDown={()=>setShowOldPassword(false)}
                              edge="end"
                            >
                        {showOldPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                          </InputAdornment>,
                        }}
                    // class={style.margin}
                    />

                    <CustomTextField
                        onChange={(e) => setNewPassword(newPassword = e.currentTarget.value)}
                        value={newPassword}
                        id="newpassword"
                        type={showNewPassword ? 'text' : 'password'}
                        label="Neues Passwort"
                        variant="outlined"
                        fullWidth={true}
                        //error={true}
                        //helperText={"Wrong pass or username"}
                        // onKeyPress={(e)  => {
                        //     if(e.key === "Enter"){
                        //         logIn(props);
                        //     }
                        // }}
                        class={classes.elementSpacing}
                        InputProps={{
                          endAdornment: <InputAdornment position="start">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={()=>setShowNewPassword(true)}
                              onMouseDown={()=>setShowNewPassword(false)}
                              edge="end"
                            >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                          </InputAdornment>,
                        }}
                    // style={style.textField}
                    />
                    <CustomTextField
                        onChange={(e) => setNewPasswordAgain(newPasswordAgain = e.currentTarget.value)}
                        value={newPasswordAgain}
                        id="oldpasswordagain"
                        type={showNewPasswordAgain ? 'text' : 'password'}
                        label="Passwort bestätigen"
                        variant="outlined"
                        fullWidth={true}
                        //error={true}
                        //helperText={"Wrong pass or username"}
                        // onKeyPress={(e)  => {
                        //     if(e.key === "Enter"){
                        //         logIn(props);
                        //     }
                        // }}
                        class={classes.elementSpacing}
                        InputProps={{
                          endAdornment: <InputAdornment position="start">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={()=>setShowNewPasswordAgain(true)}
                              onMouseDown={()=>setShowNewPasswordAgain(false)}
                              edge="end"
                            >
                        {showNewPasswordAgain ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                          </InputAdornment>,
                        }}
                    // style={style.textField}
                    />
                    <div className={classes.wrapper}>
                        <Button
                            disabled={local.loading}
                            data-testid="loginId"
                            onClick={()=>handlePasswordChange()}
                            className={buttonClassname}
                            //className={style.loginBtn}
                            variant="contained" fullWidth >
                            Kennwort ändern
                        </Button>
                        {/* {<CircularProgress size={30} className={classes.buttonProgress} />} */}
                    </div>
                    <div>
                    {local.error == true && local.errorType == 0 ? <span className={classes.incorrectCredentials}>Ihre neuen Passwörter stimmen nicht überein</span> : local.error == true && local.errorType == 1 ? <span className={classes.incorrectCredentials}>Aktuelles Passwort ist nicht korrekt</span> : "" }
                    </div>
                </Grid>
            </Grid>
            { local.loading==true ? <BackdropSpinner showLoading="true"></BackdropSpinner> : ''}
        </div>
    )
}


export default ChangePassword;
