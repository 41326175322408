import React, { useEffect } from "react";
import { useDispatch, } from "react-redux";
// import { setTitle, toggleDrawer } from "../actions/AppActions";
import Home from './Home';
import { withRouter } from "react-router-dom";
import SwipeableTemporaryDrawer from '../components/Reusable/SwipeableDrawer';
import { addNewNotification } from '../actions/NotificationActions';
import Notifications from '../components/Notifications/NotificationList';
import Urlaub from "./Urlaub/Urlaub";
import Krankenmeldung from "./Krankenmeldung/Krankenmeldung";
import Monatsleistung from "./Leistungspremie/Monatsleistung";
import Tagesleistung from "./Leistungspremie/Tagesleistung";
import Gallery from "./Gallery/Gallery";
import Kvp from "./Kvp/Kvp";
import Wegweisser from "./Wegweisser/Wegweisser";
import GalleryPreview from "./Gallery/GalleryPreview";
import WegweisserSubGroups from "./Wegweisser/WegweisserSubGroups";
import WegweisserAnswers from "./Wegweisser/WegweisserAnswers";
import Arbeitszeiten from "./FirmaInfo/Arbeitszeiten";
import VeranstaltungenList from "./FirmaInfo/VeranstaltungenList";
import Veranstaltungen from './FirmaInfo/Veranstaltungen';
import Allgemein from './FirmaInfo/Allgemein';
import UrlaubOverview from "./Urlaub/UrlaubOverview";
import { getBeraterId } from "../helpers/HelperFunctions";
import DbTasks from "../db/DbTasks";
import Zeiterfassung from "./Zeiterfassung/Zeiterfassung";

// import { HubConnectionBuilder } from '@aspnet/signalr';
//import * as signalR from '@aspnet/signalr';
import * as signalR from '@microsoft/signalr';
import Cookies from 'js-cookie';
import UsersList from "./Chat/UsersList";
import SingleChat from "./Chat/SingleChat";
import GroupChat from "./Chat/GroupChat";
import { InitialiceChatConnection } from '../actions/AppActions';
import VeranstaltungenSingle from "./FirmaInfo/VeranstaltungenSingle";
import MitarbeiterGallery from "./Gallery/MitarbeiterGallery";
import MitarbeiterGalleryMonth from "./Gallery/MitarbeiterGalleryMonth";
import GalleryPreviewMitarbeiter from "./Gallery/GalleryPreviewMitarbeiter";
import ChangePassword from "./extensions/ChangePassword";

// const classes = {
//     appbar: {
//         top: 0,
//         height: 56,
//         maxHeight: 56
//     },
//     child: {
//         marginTop: 150,
//     }

// };

const redirectToComponent = () => {
    var location = window.location.pathname;
    if (location.lastIndexOf("/") !== 0) {
        location = "/" + location.slice(1, location.indexOf("/", 1) + 1);
    }
    switch (location) {
        case "/home": return <Home />
        case "/notifications": return <Notifications />
        case "/urlaubDiesesJahr": return <Urlaub key='dieses'/>
        case "/urlaubNaechstesJahr": return <Urlaub key='nächstes' />
        case "/krankenmeldung": return <Krankenmeldung />
        case "/monatsleistung": return <Monatsleistung />
        case "/tagesleistung/": return <Tagesleistung />
        case "/gallery": return <Gallery />
        case "/gallery/": return <GalleryPreview />
        case "/kvp": return <Kvp />
        case "/wegparent/": return <Wegweisser />
        case "/wegweisser/": return <WegweisserSubGroups />
        case "/wegweisseranswer/": return <WegweisserAnswers />
        case "/arbeitszeit": return <Arbeitszeiten />
        case "/veranstaltungen/": return <VeranstaltungenSingle />
        case "/allgemein": return <Allgemein />
        case "/urlauboverview": return <UrlaubOverview />
        case "/urlauboverviewplanned": return <UrlaubOverview />
        case "/mitarbeiterhandbuch": return <Veranstaltungen />
        case "/zeiterfassung": return <Zeiterfassung />
        case "/chatlist": return <UsersList />
        case "/groupchat/": return <GroupChat/>
        case "/chat/": return <SingleChat />
        case "/veranstaltungen": return <VeranstaltungenList/>
        case "/veranstaltungen/": return <VeranstaltungenSingle/>
        case "/mitarbeitergallery" : return <MitarbeiterGallery/>
        case "/mitarbeitergallery/": return <MitarbeiterGalleryMonth/>
        case "/mitarbeitergallerypreview/": return <GalleryPreviewMitarbeiter/>
        case "/changepassword":return <ChangePassword/>

        default: return <Home />
    }
}

function MainLayout() {
    const dispatch = useDispatch();
    let connection = null;

    const listAllEventListeners = () => {
        let elements = [];
        const allElements = document.querySelectorAll('*');
        const types = [];
        for (let ev in window) {
            if (/^on/.test(ev)) types[types.length] = ev;
        }

        for (let i = 0; i < allElements.length; i++) {
            const currentElement = allElements[i];
            for (let j = 0; j < types.length; j++) {
                if (typeof currentElement[types[j]] === 'function') {
                    elements.push({
                        "node": currentElement,
                        "listeners": [{
                            "type": types[j],
                            "func": currentElement[types[j]].toString(),
                        }]
                    });
                }
            }
        }

        console.log("Listeners : ", elements);
        //return elements.filter(element => element.listeners.length)
    };

    function addNotification(e) {
        // console.log("Add notification to the toolbar !", notification);
        let notification = e.data.notification;
        console.log("Add notification res : ", notification);
        let data = {
            text: notification.text,
            description: notification.description,
            type: notification.type,
            notificationForBeraterId: notification.notificationForId
        }
        // dispatch(addNewNotification(data));

         if (notification.messageType === 4)
         { 
            let flag = true;
            let db = new DbTasks();
            
            db.getAllNotifications().then(s =>{
                s.forEach(d=>{
                    if(d.type==notification.type)
                    {
                        flag=false;
                    }
                    return;
                })
                if(flag === true)
                {
                    dispatch(addNewNotification(data));
                }
            })
         }
         else
         {
            dispatch(addNewNotification(data))
         }
    }

    const logOnConsole = (data) => {
        console.log("addNotification !!");
        // dispatch(addNewNotification(data));
    }

    let isSubscribed = false;
    //dispatch(setInitialDataFromLocalDb());

    const getNotifications = () => {
        console.log("Notification call to server");
        let beraterId = getBeraterId();
        //addNotification({text: "Arbeitszeit info", description: "Die Arbeitszeit wurde geändert. Bitte überprüfen Sie die FirmaInfo / Arbeitszeiten im Menü."});
        new DbTasks().getDataFromDb(`api/FirmaInfo/ArbeitszeitNotification?id=${beraterId}`, {}, { method: "GET" })
            .then(res => {
                if (res) addNotification({ text: "Arbeitszeit info", description: "Die Arbeitszeit wurde geändert. Bitte überprüfen Sie die FirmaInfo / Arbeitszeiten im Menü." });
            });

    }




    useEffect(() => {

        startChatConnection();
        //getNotifications();
        // console.table("Listeners :", listeners);
        if (navigator.serviceWorker) {
            navigator.serviceWorker.removeEventListener('message', addNotification, true);
            navigator.serviceWorker.addEventListener('message', addNotification, true);
        }

        try {
            Promise.resolve(Notification.requestPermission()).then(function (status) {
                if (status === 'denied') {
                    errorHandler('[Notification.requestPermission] Browser denied permissions to notification api.');
                } else if (status === 'granted') {
                    console.log('[Notification.requestPermission] Initializing service worker.');
                    initialiseServiceWorker();
                }
            }).catch(() => alert("Notifications are not supported."));
        } catch (err) {
            console.error("Notifications are not supported!", err);
            //alert("Notifications are not supported.")
        }
        // Notification && Notification.requestPermission().then(function (status) {
        //     if (status === 'denied') {
        //         errorHandler('[Notification.requestPermission] Browser denied permissions to notification api.');
        //     } else if (status === 'granted') {
        //         console.log('[Notification.requestPermission] Initializing service worker.');
        //         initialiseServiceWorker();
        //     }
        // });   
        // }



        subscribe();

        return () => {
            if (navigator.serviceWorker) navigator.serviceWorker.removeEventListener('message', addNotification, true);
            //navigator.serviceWorker.removeEventListener("message");
            console.log("Cleanup function running !");
            //clearInterval(interval);
            connection && connection.stop();
        }
    }, [])

    useEffect(() => {
        let beraterId = getBeraterId();
        new DbTasks().getDataFromDb(`api/FirmaInfo/GetEventNotifications`, {BeraterId: beraterId}, { method: "POST" }).then(res=>{
            // setState({...state, privateEvent:res.veran, publicEvent:res.veran}); 
            if(res)
            {
                if(res.veran>0 || res.allgemein>0)
                {
                    let cnt = new DbTasks().GetCountFirmaInfo().then(s=>{
                    if(s<=0)
                    {
                        dispatch(addNewNotification({ text: "Firmen info", description: "Neue eintrage in Firmen Info", notificationForBeraterId:beraterId, type:"baner" }));
                    }
                })
                }
            }
        });
      }, []);

    const startChatConnection = () => {
        const jwt = Cookies.get('__session');
        connection = new signalR.HubConnectionBuilder()
            //.withUrl("https://localhost:44393/chat", 
            .withUrl(`${process.env.REACT_APP_LOCAL_API_URL}chat`,
                {
                    accessTokenFactory: () => jwt,
                }
            ).configureLogging(signalR.LogLevel.Debug)
            .build();


        //Disable send button until connection is established
        //document.getElementById("sendButton").disabled = true;

        // connection.on("ReceiveMessage", function (user, message) {
        //     console.log("chat user and message : ", user, message);
        //     // var msg = message.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
        //     // var encodedMsg = user + " says " + msg;
        //     // var li = document.createElement("li");
        //     // li.textContent = encodedMsg;
        //     // document.getElementById("messagesList").appendChild(li);
        // });        

        connection
            .start() //{ transport: 'longPolling' }
            .then(function () {
                //document.getElementById("sendButton").disabled = false;
                console.log("Chat Connection started !");

            }).catch(function (err) {
                console.log("Chat error : ", err);
                connection.stop().then(res => console.log("Connection stoped."));
                //return console.error(err.toString());
            });

        dispatch(InitialiceChatConnection(connection));
    }

    function initialiseServiceWorker() {
        if ('serviceWorker' in navigator) {
            //sw.register();
            initialiseState();
            //navigator.serviceWorker.register(sw).then(handleSWRegistration);
        } else {
            errorHandler('[initialiseServiceWorker] Service workers are not supported in this browser.');
        }
    };

    function handleSWRegistration(reg) {
        if (reg.installing) {
            console.log('Service worker installing');
        } else if (reg.waiting) {
            console.log('Service worker installed');
        } else if (reg.active) {
            console.log('Service worker active');
        }

        initialiseState(reg);
    }

    // function sendNotification() {
    //     const img = "/images/jason-leung-HM6TMmevbZQ-unsplash.jpg";
    //     const text = "Take a look at this brand new t-shirt!";
    //     const title = "New Product Available";
    //     const options = {
    //       body: text,
    //       icon: "/images/jason-leung-HM6TMmevbZQ-unsplash.jpg",
    //       vibrate: [200, 100, 200],
    //       tag: "new-product",
    //       image: img,
    //       badge: "https://spyna.it/icons/android-icon-192x192.png",
    //       actions: [{ action: "Detail", title: "View", icon: "https://via.placeholder.com/128/ff0000" }]
    //     };
    //     navigator.serviceWorker.ready.then(function(serviceWorker) {
    //       serviceWorker.showNotification(title, options);
    //     });
    //   }
    // Once the service worker is registered set the initial state
    function initialiseState(reg) {
        // Are Notifications supported in the service worker?
        // if (!(reg.showNotification)) {
        //     errorHandler('[initialiseState] Notifications aren\'t supported on service workers.');
        //     return;
        // }

        // Check if push messaging is supported
        if (!('PushManager' in window)) {
            errorHandler('[initialiseState] Push messaging isn\'t supported.');
            return;
        }


        // We need the service worker registration to check for a subscription
        navigator.serviceWorker && navigator.serviceWorker.ready.then(function (reg) {
            // Do we already have a push message subscription?
            reg.pushManager.getSubscription()
                .then(function (subscription) {
                    isSubscribed = subscription;
                    //sendNotification();
                    if (isSubscribed) {
                        console.log('User is already subscribed to push notifications');
                    } else {
                        console.log('User is not yet subscribed to push notifications');
                    }
                })
                .catch(function (err) {
                    console.log('[req.pushManager.getSubscription] Unable to get subscription details.', err);
                });
        });
    }
    function errorHandler(message, e) {
        if (typeof e == 'undefined') {
            e = null;
        }
        console.error(message, e);
    }

    const urlB64ToUint8Array = (base64String) => {
        var padding = '='.repeat((4 - base64String.length % 4) % 4);
        var base64 = (base64String + padding)
            .replace(/\-/g, '+')
            .replace(/_/g, '/');

        var rawData = window.atob(base64);
        var outputArray = new Uint8Array(rawData.length);

        for (var i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }

    const base64Encode = (arrayBuffer) => {
        return btoa(String.fromCharCode.apply(null, new Uint8Array(arrayBuffer)));
    }

    const subscribe = () => {
        navigator.serviceWorker && navigator.serviceWorker.ready.then(function (reg) {
            var subscribeParams = { userVisibleOnly: true };

            //Setting the public key of our VAPID key pair.
            var applicationServerKey = urlB64ToUint8Array("BDewT6-XusZ82RQ0UIJswooRsh4Xrf1UYUvMo0GvUOHSipkxBPgMxcBDB2bQ61-nSi64Pi5A3ZM3LGLolXd3mvs");
            subscribeParams.applicationServerKey = applicationServerKey;

            if (window.safari) {
                Promise.resolve(window.safari.pushNotification.subscribe(subscribeParams)).then(function (subscription) {
                    isSubscribed = true;

                    var p256dh = base64Encode(subscription.getKey('p256dh'));
                    var auth = base64Encode(subscription.getKey('auth'));
                    //var subs = base64Encode(subscription.getKey('subscription'));

                    var data = { PushEndpoint: subscription.endpoint, PushP256DH: p256dh, PushAuth: auth };
                    console.log("Subscription data : ", data);

                    new DbTasks().getDataFromDb("api/Devices/Create", { ...data }, { method: "POST" })
                        .then(res => {
                            if (res) {
                                console.log("This device is added to subscription list.");
                            } else {
                                console.warn("Adding device to subscription list failed.");
                            }
                        })
                })
                    .catch(function (e) {
                        errorHandler('[subscribe] Unable to subscribe to push', e);
                    });
            } else {
                Promise.resolve(reg.pushManager.subscribe(subscribeParams)).then(function (subscription) {
                    isSubscribed = true;

                    var p256dh = base64Encode(subscription.getKey('p256dh'));
                    var auth = base64Encode(subscription.getKey('auth'));
                    //var subs = base64Encode(subscription.getKey('subscription'));

                    var data = { PushEndpoint: subscription.endpoint, PushP256DH: p256dh, PushAuth: auth };
                    console.log("Subscription data : ", data);

                    new DbTasks().getDataFromDb("api/Devices/Create", { ...data }, { method: "POST" })
                        .then(res => {
                            if (res) {
                                console.log("This device is added to subscription list.");
                            } else {
                                console.warn("Adding device to subscription list failed.");
                            }
                        })
                })
                    .catch(function (e) {
                        errorHandler('[subscribe] Unable to subscribe to push', e);
                    });
            }

            // reg.pushManager.subscribe(subscribeParams)
            //     .then(function (subscription) {
            //         isSubscribed = true;

            //         var p256dh = base64Encode(subscription.getKey('p256dh'));
            //         var auth = base64Encode(subscription.getKey('auth'));
            //         //var subs = base64Encode(subscription.getKey('subscription'));

            //         var data = { PushEndpoint: subscription.endpoint, PushP256DH: p256dh, PushAuth: auth };
            //         console.log("Subscription data : ", data);

            //         new DbTasks().getDataFromDb("api/Devices/Create", { ...data }, { method: "POST" })
            //             .then(res => {
            //                 if (res) {
            //                     console.log("This device is added to subscription list.");
            //                 } else {
            //                     console.warn("Adding device to subscription list failed.");
            //                 }
            //             })
            //         // $('#PushEndpoint').val(subscription.endpoint);
            //         // $('#PushP256DH').val(p256dh);
            //         // $('#PushAuth').val(auth);
            //     })
            //     .catch(function (e) {
            //         errorHandler('[subscribe] Unable to subscribe to push', e);
            //     });
        });
    }

    // const onNotifReceived = (res) => {
    //     console.info('Yayyyyy, I just received a notification!!!', res);
    //     //alert(res);
    //     this.setState({ message: res });
    // }

    // const doNothing = () => {
    //     window.location.reload();
    // };

    // render() {        
    //const version = this.props.application.version;
    // let showUpdateBtn = this.props.application.serviceWorkerUpdated;
    return (
        <div>
            <SwipeableTemporaryDrawer />
            {/* <p>{this.state.message || ""}</p> */}
            <div style={{ marginTop: "0px" }}>
                {/* <Switch>
                        <Route
                            exact
                            path="/home"
                            render={() => {
                                return <Home />;
                            }}
                        />


                    </Switch> */}
                {redirectToComponent()}
            </div>
        </div>
    );
    // }
}

export default withRouter(MainLayout);

// const mapStateToProps = state => ({
//     application: state.app,
//     pageTitle: state.app.pageTitle
// });

// export default connect(
//     mapStateToProps,
//     { setTitle, toggleDrawer }
// )(MainLayout);
