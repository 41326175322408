import { UPDATE_MESSAGE_LIST, ADD_MESSAGE_TO_LIST, SET_BERATER_CHAT_NAME  } from '../actions/Types';

export const initiateMesageList = val => {
    console.log("initiateMesageList ---> poziv",val);
    return {
        type: UPDATE_MESSAGE_LIST,
        payload: val.messages,
        beraterName: val.beraterName || "",
        groupName:val.groupName || "",
    };
};

export const addMessagesToList = val => {
    return {
        type: ADD_MESSAGE_TO_LIST,
        payload: val,
        //beraterName: val.beraterName || ""
    };
};

export const setBeraterChatName = val => {
    return {
        type: SET_BERATER_CHAT_NAME,
        payload: val,
    }
}