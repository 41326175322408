//User
export const LOGIN_REQUEST = 'USERS_LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'USERS_LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'USERS_LOGIN_FAILURE';
export const LOGOUT = 'USERS_LOGOUT';

////APP
export const TOGGLE_DRAWER = "TOGGLE_DRAWER";
export const SET_TITLE = "SET_TITLE";
export const INITIALISE_STATE = "INITIALISE_STATE";
export const CHANGE_FILTER = "CHANGE_FILTER";
export const TOGGLE_SPINNER = "TOGGLE_SPINNER";
export const CHAT_CONNECTION = "CHAT_CONNECTION";
export const SET_URLAUB_JAHR = "SET_URLAUB_JAHR";
////DB 


////Notifications
export const ADD_NEW_NOTIFICATION = 'ADD_NEW_NOTIFICATION';
export const MARK_NOTIFICATION_AS_READ = "MARK_NOTIFICATION_AS_READ";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";

//Chat messages
export const UPDATE_MESSAGE_LIST = 'UPDATE_MESSAGE_LIST';
export const ADD_MESSAGE_TO_LIST = 'ADD_MESSAGE_TO_LIST';
export const SET_BERATER_CHAT_NAME = 'SET_BERATER_CHAT_NAME';

//Service worker
export const SW_INIT = 'SW_INIT';
export const SW_UPDATE = 'SW_UPDATE';
export const INSTALL_APP = 'INSTALL_APP';
export const SHOW_INSTALL_PROMPT = 'SHOW_INSTALL_PROMPT';