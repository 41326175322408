import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { markAsRead, removeNotification } from '../../actions/NotificationActions';
import CardContainerSmall from '../Reusable/CardContainerSmall';
import { FaBell } from 'react-icons/fa';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import { getBeraterId } from '../../helpers/HelperFunctions';

export let util ={markNotificationAsRead:null};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(1),
    //maxWidth: 400,  
    //width: '100%',
    //display: 'flex',
    alignContent: 'center',
    // alignItems: 'f'
    //fontFamily: 'sans-serif',
  },
  paper: {
    maxWidth: '100%',
    //margin: theme.spacing(1),
    //margin: `${theme.spacing(1)}px auto`,
  },
  "@keyframes rotating": {
    "0%": {
      //opacity: 0,
      // transform: "translateY(-200%)"
      transform: "rotate(-45deg)",
    },
    "100%": {
      //opacity: 1,
      // transform: "translateY(0)"
      transform: "rotate(45deg)",
    },
  },
  animatedItem: {
    animation: `$rotating 1000ms ${theme.transitions.easing.easeInOut} infinite alternate`
  },
}));

function NotificationList(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const list1 = useSelector(state => state.notifications.list);
  const list = list1.filter(s=>s.notificationForBeraterId === getBeraterId());

  util.markNotificationAsRead = (item) => {
    
    
    if(item.type !="baner")
    {
      dispatch(removeNotification(item.id));
      props.history.push(`/chat/${item.type}`);
    }
    else
    {
      dispatch(markAsRead(item.id));
    }
    
  }

  return (
    <div className={classes.root}>
      {/* <h1>Notifications list :</h1> */}
      <Grid container direction="column" spacing={1} alignContent='center' justify='center'>
        {list && list.length === 0 && <p>There is no new notifications.</p>}
        {list.map((item, index) =>

          <Grid item lg={6} md={6} sm={12} xs={12} key={index}>
            <Paper className={classes.paper} onClick={() => util.markNotificationAsRead(item)} data-testid={`not-${item.text}`}>
              <CardContainerSmall
                key={index}
                title={item.text}
                number={""}
                subtitle={item.description}
                class={"blue"}
                mainIcon={<FaBell size={"2em"} color="white" className={item.seen ? "" : classes.animatedItem} />}
              />
            </Paper>
          </Grid>

        )}
      </Grid>
    </div>
  )
}

export default withRouter(NotificationList);