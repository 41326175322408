import { ADD_MESSAGE_TO_LIST, UPDATE_MESSAGE_LIST, SET_BERATER_CHAT_NAME } from "../actions/Types";
const initialState = {
    messageList: [],
    beraterName: "",
    groupName:""
}

export default function messages (state = initialState, action){
    switch (action.type){
        case UPDATE_MESSAGE_LIST:
            return {...state, messageList: action.payload, beraterName: action.beraterName, groupName:action.groupName};
        case ADD_MESSAGE_TO_LIST:        
            return { ...state, messageList: [...state.messageList, action.payload] };
        case SET_BERATER_CHAT_NAME: 
            return { ...state, beraterName: action.payload};
        default:
            return state;
    }
}