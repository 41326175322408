import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import DraftsIcon from '@material-ui/icons/Drafts';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import Badge from '@material-ui/core/Badge';
import { Chat, Info, Schedule, Event, Today, FlightTakeoff, LocalHospital, Euro, CalendarToday, PhotoLibrary, MenuBook, Home, Fastfood, Map,  DateRange } from '@material-ui/icons';
//import HomeIcon from '@material-ui/icons/Home';
import { withRouter } from 'react-router-dom';
import { FaIdCardAlt } from 'react-icons/fa';
import DbTasks from '../../db/DbTasks';
import { getBeraterId, getBeraterName } from '../../helpers/HelperFunctions';

import headerImage from "../../assets/images/header_image.png";

import "../../assets/styles/Drawer/Drawer.css";



const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

function NestedList(props) {
  const classes = useStyles();
  const [state, setOpen] = React.useState({
    firmaOpen: false,
    leistungOpen: false,
    wegOpen: false,
    zeiterfassungOpen: false,
    urlaubOpen: false,
  });

  const dispatch = useDispatch();
  const [menuState, setMenuState] = useState({
    wegweisserList: [],
    privateEvent: 0,
    publicEvent: 0
  })
  //const [wegweisserList, setWegweisserList] = useState([]);
  const version = useSelector(state => state.app.version);
  let beraterId = getBeraterId();

  useEffect(() => {
    new DbTasks().getDataFromDb(`api/Wegweisser/WegParent`, {}, { method: "GET" })
      .then(res => {
        //setMenuState({...menuState, wegweisserList: res});
        return res;
        //setWegweisserList(res);
      }).then((wegList) => new DbTasks().getDataFromDb(`api/FirmaInfo/GetEventNotifications`, {BeraterId: beraterId}, { method: "POST" })
      .then(res => {
        setMenuState({...menuState, privateEvent: res.veran, publicEvent: res.allgemein, wegweisserList: wegList});

        console.log(wegList);
        //console.log("Event notifiaction data : ", res);
      }));
  }, []);

  const handleClick = (prop) => {
    switch (prop) {
      case "firmainfo":
        setOpen({ ...state, firmaOpen: !state.firmaOpen });
        break;
      case "leistung":
        setOpen({ ...state, leistungOpen: !state.leistungOpen });
        break;
      case "wegOpen":
        setOpen({ ...state, wegOpen: !state.wegOpen });
        break;
      case "zeiterfassungOpen":
        setOpen({...state, zeiterfassungOpen: !state.zeiterfassungOpen });
        break;
        case "urlaubOpen":
          setOpen({...state, urlaubOpen: !state.urlaubOpen });
          break;
      default:
        break;
    }
    //setOpen(!open);
  };

  const redirectTo = (url) => {
    props.history.push("/" + url);
    dispatch(props.menuOpen(false))
  }

  let firmaOpen = state.firmaOpen, leistungOpen = state.leistungOpen, wegOpen = state.wegOpen, zeiterfassungOpen = state.zeiterfassungOpen, urlaubOpen = state.urlaubOpen;

  return (

    <>
      <div className="drawer-header">
        <img src={headerImage} alt="" />
        <div className="header-info">
          <FaIdCardAlt size={"2.6rem"} color="#fff" />
          <div className="app-info">
            <span className="title">MitarbeiterApp</span>
            <span className="app-version">{version}</span>
          </div>
        </div>

        <span className="user-name">{getBeraterName()}</span>

        <Box className="btn-close">
          <IconButton
            onClick={() => dispatch(props.menuOpen(false))}
            aria-label="close" className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </Box>
      </div>
      <List
        className="drawer-wrapper"
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <Box className="links-list">
        <ListItem button onClick={() => redirectTo("home")}>
            <ListItemIcon>
              <Home color="primary" />
            </ListItemIcon>
            <ListItemText primary="Startseite" />
          </ListItem>
          <ListItem button onClick={() => redirectTo("chatlist")}>
            <ListItemIcon>
              <Chat color="primary" />
            </ListItemIcon>
            <ListItemText primary="Chat" />
          </ListItem>
          <ListItem button onClick={() => handleClick("firmainfo")}>
            <ListItemIcon>
              <Info color="primary" />
            </ListItemIcon>
            <ListItemText primary="Firmen Info" />
            {firmaOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse className="dropdown" in={firmaOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} onClick={() => redirectTo("veranstaltungen")}>
                <ListItemIcon>
                  <Event color="primary" />
                </ListItemIcon>
                <ListItemText primary="Veranstaltungen" />
                {menuState.privateEvent > 0 && <Badge badgeContent={menuState.privateEvent} color="secondary">      </Badge>}
              </ListItem>

              <ListItem button className={classes.nested} onClick={() => redirectTo("allgemein")}>
                <ListItemIcon>
                  <Today color="primary" />
                </ListItemIcon>
                <ListItemText primary="Allgemein" />
                {menuState.publicEvent > 0 && <Badge badgeContent={menuState.publicEvent} color="secondary">      </Badge>}
              </ListItem>

              <ListItem button className={classes.nested} onClick={() => redirectTo("mitarbeiterhandbuch")}>
                <ListItemIcon>
                  <MenuBook color="primary" />
                </ListItemIcon>
                <ListItemText primary="Mitarbeiterhandbuch" />
              </ListItem>

              <ListItem button onClick={() => handleClick("wegOpen")}>
                <ListItemIcon>
                  {/* <DraftsIcon color="primary" /> */}
                  <Map color="primary" />
                </ListItemIcon>
                <ListItemText primary="Wegweiser" />
                {wegOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              {menuState.wegweisserList.length > 0 && (
              <Collapse className="dropdown" in={wegOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {menuState.wegweisserList.map(item =>
                    <ListItem key={item.id} button className={classes.nested} onClick={() => redirectTo(`wegparent/${item.id}`)}>
                      <ListItemIcon>
                        {/* <DraftsIcon color="primary" /> */}
                        {
                          item.name === "Mittagessen" ? <Fastfood color="primary" /> : <Map color="primary" />
                        }
                      </ListItemIcon>
                      <ListItemText primary={item.name} />
                    </ListItem>
                  )}
                </List>
              </Collapse>
            )}

            </List>
          </Collapse>
            <ListItem button onClick={() => handleClick("zeiterfassungOpen")}>
              <ListItemIcon>
              <Schedule color="primary" />
              </ListItemIcon>
              <ListItemText primary="Zeiterfassung" />
              {zeiterfassungOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse className="dropdown" in={zeiterfassungOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} onClick={() => redirectTo("arbeitszeit")}>
                <ListItemIcon>
                  <Schedule color="primary" />
                </ListItemIcon>
                <ListItemText primary="Schichtplan" />
              </ListItem>

              <ListItem button className={classes.nested} onClick={() => redirectTo("zeiterfassung")}>
                <ListItemIcon>
                <Schedule color="primary" />
                </ListItemIcon>
                <ListItemText primary="Zeiterfassung" />
              </ListItem>
            </List>
          </Collapse>
          {/* <ListItem button onClick={() => redirectTo("urlaub")}>
            <ListItemIcon>
              <FlightTakeoff color="primary" />
            </ListItemIcon>
            <ListItemText primary="Urlaub" />
          </ListItem> */}

          {/* start of Hamzas code, ticket #10464 */}
          <ListItem button onClick={() => handleClick("urlaubOpen")}>
            <ListItemIcon>
              <FlightTakeoff color="primary" />
            </ListItemIcon>
            <ListItemText primary="Urlaub" />
            {urlaubOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse className="dropdown" in={urlaubOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} onClick={() => {
                redirectTo("urlaubDiesesJahr");
              }}>
                <ListItemIcon>
                  <Today color="primary" />
                </ListItemIcon>
                <ListItemText primary="Dieses Jahr"/>
              </ListItem>

              <ListItem button className={classes.nested} onClick={() => {
                redirectTo("urlaubNaechstesJahr");
              }}>
                <ListItemIcon>
                <DateRange color="primary" />
                </ListItemIcon>
                <ListItemText primary="Nächstes Jahr"/>
              </ListItem>
            </List>
          </Collapse>

          <ListItem button onClick={() => redirectTo("krankenmeldung")}>
            <ListItemIcon>
              <LocalHospital color="primary" />
            </ListItemIcon>
            <ListItemText primary="Krankenmeldung" />
          </ListItem>
          <ListItem button onClick={() => handleClick("leistung")}>
            <ListItemIcon>
              <Euro color="primary" />
            </ListItemIcon>
            <ListItemText primary="Leistungsprämie" />
            {leistungOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse className="dropdown" in={leistungOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} onClick={() => redirectTo("tagesleistung/-1")}>
                <ListItemIcon>
                  <Today color="primary" />
                </ListItemIcon>
                <ListItemText primary="Tagesleistung" />
              </ListItem>
              <ListItem button className={classes.nested} onClick={() => redirectTo("monatsleistung")}>
                <ListItemIcon>
                  <CalendarToday color="primary" />
                </ListItemIcon>
                <ListItemText primary="Monatsleistung" />
              </ListItem>
            </List>
          </Collapse>

          <ListItem button onClick={() => redirectTo("kvp")}>
            <ListItemIcon>
              <DraftsIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="KVP" />
          </ListItem>
          <ListItem button onClick={() => redirectTo("gallery")}>
            <ListItemIcon>
              <PhotoLibrary color="primary" />
            </ListItemIcon>
            <ListItemText primary="Gallerie" />
          </ListItem>
        </Box>
      </List>
    </>
  );
}

export default withRouter(NestedList);
