import * as React from 'react';
import { useEffect, useState } from 'react';
import moment from 'moment-with-locales-es6';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import WorkIcon from '@material-ui/icons/Work';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import { makeStyles } from '@material-ui/core';
import { getBeraterId } from '../../helpers/HelperFunctions';
import {getDataFromDatabase} from '../../db/api';
import EventNoteIcon from '@material-ui/icons/EventNote';
import BackdropSpinner from '../Reusable/BackdropSpinner';
import { withRouter } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
   root:{
    width: '100%',
    margin:'auto',
    maxWidth: 500,
    bgcolor: 'background.paper',
    ['@media (max-width: 600px)']: {
        maxWidth: 330,
    }
   },
   paper: {
    width: '100%',
    backgroundColor: '#ebeced',
    height: '60px',
    marginTop:'3px'
  },
  paperTitle: {
    textAlign: 'left',
    marginTop: '5px',
    marginBottom: '5px'
  },
  paperTime: {
    textAlign: 'right',
    marginTop: '5px',
    marginBottom: '5px',
    marginRight: '20px'
  }
}));

function VeranstaltungenList(props) {

    const classes = useStyles();
    const [showLoading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [dates, setDates] = useState([]);
    let dts = []; //array that stores array of StartDates
    let uqdts = []; //array that stores unique array of StartDates

    const handleEventClick = (id) =>{
        props.history.push(`/veranstaltungen/${id}`)
    }

    useEffect(() => {
        let beraterId = getBeraterId();
        //let view = window.location.pathname === "/mitarbeiterhandbuch" ? 'api/FirmaInfo/Veranstaltungen' : 'api/FirmaInfo/VeranstaltungenOnly' //if view is Veranstaltungen, then we use controller just for Veranstaltungen data
        //let eventType = window.location.pathname === "/mitarbeiterhandbuch" ? 2 : 0; // 2 - stands for Mitarbeiterhandbuch type of event in Suite. 0 is for private events / Veranstaltungen
        getDataFromDatabase('api/FirmaInfo/VeranstaltungenOnly', { BeraterId: beraterId, Type: 0 }, { method: "POST" })
            .then(res => {
                console.log("lista---->",res);
                if (!res) {
                    setLoading(false);
                    return;
                } else {
                    setData(res);
                    setLoading(false);
                    res.map(item=>{
                        dts.push(moment(item.startDate).locale('de').format("dddd, DD.MMMM"));
                    });
                    uqdts = [...new Set(dts)]
                    setDates(uqdts);
                }
            })
        return () => {
            console.log("Running cleanup function !");
        }
    }, [])

  return (
      data.length > 0 ? (
      dates.map((item,index)=>{
          return (
        <div className={classes.root} key={index}>
            <div className={classes.paperTitle}>
                <Typography variant="h6" style={{borderBottom:'2px solid blue'}}>{item}</Typography>
            </div>
                {data.map((v,index)=>{
                    if(item == moment(v.startDate).locale('de').format("dddd, DD.MMMM"))
                    {
                        return (<Paper className={classes.paper} key={index} onClick={()=>handleEventClick(v.id)}>
                            <Grid container  direction="row" spacing={0} alignContent='flex-start' justify='flex-start' alignItems='flex-start'>
                            <Grid item lg={1} md={1} sm={1} xs={1} style={{alignItems:'center'}}>
                                <div style={{width:'5px', height:'50px', backgroundColor:'blue', margin: 'auto',marginTop:"5px", borderRadius:'3px'}}>
            
                                </div>
                            </Grid>
                            <Grid item lg={8} md={8} sm={8} xs={8}>
                                <div className={classes.paperTitle}>
                                <Typography variant="h6">{v.subject}</Typography>
                                </div>
                            </Grid>
                            <Grid item lg={3} md={3} sm={3} xs={3}>
                                <div className={classes.paperTime}>
                                    {v.allDay == true ? (<div><Typography>00:00</Typography>
                                <Typography>24:00</Typography></div>):(<div><Typography>{moment(v.startDate).locale('de').format("HH:mm")}</Typography>
                                <Typography>{moment(v.endDate).locale('de').format("HH:mm")}</Typography></div>)}
                                </div>
                            </Grid>
                            </Grid>
                        </Paper>)
                    }
                })}
            </div>
          )
      })):"Es gibt keine Daten"
  );
}

export default withRouter(VeranstaltungenList);