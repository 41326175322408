import DbTasks from '../db/DbTasks';
import { INITIALISE_STATE, INSTALL_APP, SHOW_INSTALL_PROMPT, CHAT_CONNECTION, SW_INIT, SW_UPDATE, SET_URLAUB_JAHR  } from '../actions/Types';

import {
    TOGGLE_DRAWER,
    SET_TITLE,
    TOGGLE_SPINNER,
} from "./Types";
import { Exposure } from '@material-ui/icons';

export const toggleDrawer = val => {
    return {
        type: TOGGLE_DRAWER,
        payload: val
    };
};

export const setTitle = val => {
    return {
        type: SET_TITLE,
        payload: val
    };
};

export const setInitialDataFromLocalDb = () => async dispatch => {
    let data = await new DbTasks().initialDataLoad();

    dispatch({
        type: INITIALISE_STATE,
        payload: data
    })
}

export const getDataFromApi = () => async dispatch => {
    let data = await new DbTasks().loadData();

    //   return dispatch => {
    dispatch({
        type: INITIALISE_STATE,
        payload: data
    });    
}

export const toggleSpinner = val => {
    return {
        type: TOGGLE_SPINNER,
        payload: val
    };
};

export const InstallPrompt = (param) => {
    return {
        type: INSTALL_APP,
        payload: param
    }
}

export const InstallShowed = () => {
    return {
        type: SHOW_INSTALL_PROMPT,
        payload: true
    }    
}

export const InitialiceChatConnection = (connection) => {
    return {
        type: CHAT_CONNECTION,
        payload: connection
    }    
}

export const init = () =>{
    return{
        type:SW_INIT,
        payload:true
    }
}

export const update = () =>{
    return{
        type:SW_UPDATE,
        payload:true
    }
}

export const setUrlaubJahr = (year) => {
    return {
        type: SET_URLAUB_JAHR,
        payload: year
    }
}