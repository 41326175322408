import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Provider } from 'react-redux';
import configureStore from './store';
import { SW_INIT, SW_UPDATE } from './actions/Types';
import { NotificationContainer } from 'react-notifications';
import "core-js/stable";
import "regenerator-runtime/runtime";

const store = configureStore();

ReactDOM.render(<Provider store={store}>
    <App />
    <NotificationContainer />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
serviceWorkerRegistration.register({
  onSuccess: () => store.dispatch({ type: SW_INIT }),
  onUpdate: registration =>
    store.dispatch({ type: SW_UPDATE, payload: registration }),
  onWaiting: waiting =>
    store.dispatch({ type: SW_UPDATE, payload: waiting })
});