import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(0),
        //fontFamily: 'sans-serif',
    },
    cardRoot: {
        minWidth: 245,
        height: 85,
        //margin: theme.spacing(1),
        backgroundColor: '#8992d7',
        color: '#FFFFFF',
        textTransform: 'uppercase',
        textAlign: 'left',
        fontWeight: 'bold',
        padding: 0,
        // position: 'relative',        
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: '#FFFFFF',
        // backgroundColor: '#8992d7'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: '0.8em',
        textAlign: 'left',
        fontWeight: 'bold'

    },
    pos: {
        marginBottom: 12,
        fontSize: 10
    },
    green: {
        backgroundColor: '#79c350'
    },
    red: {
        backgroundColor: '#f06292'
    },
    blue: {
        backgroundColor: '#45bbdf'
    },
    palm: {
        height: 40
    },
    circleStyle: {
        //padding:10,
        //marginTop: 0,
        //marginRight: '30%',
        //marginLeft: 0,
        //display: "inline-block",
        // position:'absolute',
        backgroundColor: '#FFFFFF',
        borderRadius: "50%",
        width: 50,
        height: 50,
        //right: 0,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        margin: 0,
        opacity: 0.3,
        position: 'absolute',

    },
    circle: {
        position: 'relative',
        width: 'inherit',
        height: 'inherit',
        textAlign: 'center'
    },
    button: {
        padding: theme.spacing(2),
        //margin: theme.spacing(1)
    },
    ravno: {
        textAlign: 'right'
    },
    iconContainer: {
        //paddingTop: 10,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 70,
        height: 70,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    }
}));


export default function CardContainerSmall(props) {
    const classes = useStyles();
    return (
        <Card className={clsx(classes.cardRoot, classes[props.class || ""])} variant="outlined">
            <Grid container justify="center" >
                <Grid item xs={8} sm={8} className={classes.paper}>
                    <Typography className={classes.title} data-testid="title">
                        {props.title}
                        </Typography>
                    <Typography className={classes.pos} data-testid="subtitle">
                        {props.subtitle}: {props.number}
                    </Typography>
                </Grid>
                <Grid item xs={4} sm={4} >
                    <div className={classes.circle}>
                        <div className={classes.circleStyle}>
                        </div>
                        <div className={classes.iconContainer}>
                            {props.mainIcon}
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Card>
    )
}
