import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(0),
        //fontFamily: 'sans-serif',
    },
    cardRoot: {
        minWidth: 245,
        height: 70,
        //margin: theme.spacing(1),
        backgroundColor: '#8992d7',
        color: '#FFFFFF',
        textTransform: 'uppercase',
        textAlign: 'left',
        fontWeight: 'bold',
        padding: 5,
        // position: 'relative',        
    },
    cardRootMini: {
        height: 50,
        backgroundColor: '#8992d7',
        color: '#FFFFFF',
        textTransform: 'uppercase',
        textAlign: 'left',
        fontWeight: 'bold',
        padding: 0,
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: 'grey',
        // backgroundColor: '#8992d7'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: '0.8em',
        textAlign: 'left',
        fontWeight: 'bold'

    },
    pos: {
        marginBottom: 12,
        fontSize: 10
    },
    green: {
        backgroundColor: '#79c350'
    },
    red: {
        backgroundColor: '#f06292'
    },
    blue: {
        backgroundColor: '#45bbdf'
    },
    white: {
        backgroundColor: '#FFFFFF',
        color: 'grey'
    },
    holiday: {
        backgroundColor: '#79c350',
        color: '#ffffff !important',
        '& *>p':{
            color: '#ffffff !important',
        }
        //color: 'grey'
    },

    palm: {
        height: 40
    },
    circleStyle: {
        backgroundColor: '#45bbdf',
        borderRadius: "50%",
        width: 50,
        height: 50,
        //right: 0,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        margin: 0,
        opacity: 0.3,
        position: 'absolute',
    },
    circleStyleMini: {
        backgroundColor: '#45bbdf',
        borderRadius: "50%",
        width: 30,
        height: 30,
        //right: 0,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        margin: 0,
        opacity: 0.3,
        position: 'absolute',
    },
    circle: {
        position: 'relative',
        width: 'inherit',
        height: 'inherit',
        textAlign: 'center'
    },
    button: {
        padding: theme.spacing(2),
        //margin: theme.spacing(1)
    },
    ravno: {
        textAlign: 'right'
    },
    iconContainer: {
        //paddingTop: 10,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 50,
        height: 50,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    number: {
        fontSize: '1.5em',
        textAlign: 'left',
        padding: theme.spacing(1),
    },
    numberMini: {
        fontSize: '1.5em',
        textAlign: 'left',
        padding: 5,
    }
}));


export default function CardContainerDates(props) {
    const classes = useStyles(props);
    return (
        <Card className={clsx(classes.cardRootMini, classes[props.class || ""])} variant="outlined">
            <Grid container justify="center" >
                {props.useMini ? (
                    <>
                        <Grid item xs={2} sm={2} data-testid="mini">
                            <Typography className={classes.number} data-testid="number">
                                {props.number}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} className={classes.paper}>
                            <Typography className={classes.title} data-testid="title">
                                {props.title}
                            </Typography>
                            <Typography className={classes.pos} data-testid="subtitle">
                                {props.subtitle}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} className={classes.circle}>
                            <div className={classes.circle}>
                                {props.class !== "holiday" &&
                                    <div className={classes.circleStyleMini}>
                                    </div>}

                                <div className={classes.iconContainer}>
                                    {props.mainIcon}
                                </div>
                            </div>
                        </Grid>
                    </>
                ) : (<>
                    <Grid item xs={6} sm={6} data-testid="maxi">
                        <Typography className={classes.number} data-testid="number">
                            {props.number}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} className={classes.paper} data-testid="title">
                        <Typography className={classes.title}>
                            {props.title}
                        </Typography>
                        <Typography className={classes.pos} data-testid="subtitle" >
                            {props.subtitle}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sm={2} className={classes.circle}>
                        <div className={classes.circle}>
                        {props.class !== "holiday" &&
                                    <div className={classes.circleStyleMini}>
                                    </div>}
                            <div className={classes.iconContainer}>
                                {props.mainIcon}
                            </div>
                        </div>
                    </Grid>
                </>
                )}

            </Grid>
        </Card>
    )
}
