import { combineReducers } from 'redux';

import appReducer from './AppReducer';
import notificationReducer from './NotificationReducer';
import chat from './MessageReducer';

export default combineReducers({
    app: appReducer,
    notifications: notificationReducer,
    chat: chat,
})