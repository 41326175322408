import Cookies from 'js-cookie';
import { logOut } from '../helpers/HelperFunctions';

const getRequestOption = (method, data, useContentType) => {
  const jwt = Cookies.get('__session');
  const requestOption = {
    "crossDomain": true,
    "method": method,
    "mode": "cors", // no-cors, cors, *same-origin
    "headers": {
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      "Authorization": 'Bearer ' + jwt
    },
    "credintials": "include",
    // "body": JSON.stringify({ ...data })
  }
  if (useContentType) requestOption.headers["content-type"] = "application/json; charset=utf-8";
  if (method === "POST") requestOption.body = JSON.stringify({ ...data });
  return requestOption;
};

const tryToGetNewToken = async (response, getDataAgain) => {
  // let jwtToken = this.getJwtToken();
  const jwtToken = Cookies.get('__session');
  if (response.status === 401 && response.headers.has('Token-Expired')) {
    logOut();
    //flag = true;

    // var refreshToken = this.getRefreshToken();

    // var refreshResponse = await this.refresh(jwtToken, refreshToken);
    // if (!refreshResponse.ok) {
    //   return response; //failed to refresh so return original 401 response
    // }
    // var jsonRefreshResponse = await refreshResponse.json(); //read the json with the new tokens

    // this.saveJwtToken(jsonRefreshResponse.token);
    // this.saveRefreshToken(jsonRefreshResponse.refreshToken);
    // return await getDataAgain(); //repeat the original request
  }
  // else if (response.status === 401 && flag === false) {
  //   //console.log("Flag :");
  //   localStorage.removeItem("token");
  //   window.location.reload();
  // }
  else { //status is not 401 and/or there's no Token-Expired header
    localStorage.removeItem("token");
    window.location.reload();
    //return response; //return the original 401 response
  }
}

export const login = async (username, password, settings) => {
  const jwt = Cookies.get('__session');
  const requestOptions = {
    "crossDomain": true,
    "method": "POST",
    "mode": "cors", // no-cors, cors, *same-origin
    "headers": {
      "content-type": "application/json; charset=utf-8",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      "Authorization": 'Bearer ' + jwt,
    },
    "credintials": "include",
    //"body": "password=" + password + "&username=" + username
    "body": JSON.stringify({ username: username, password: password })
  };

  // return {
  //     name: "Grogot",
  //     lastName: "Test",
  //     error: ""
  // }



  return fetch(`${process.env.REACT_APP_LOCAL_API_URL}api/User/Authenticate`, requestOptions)
    //return fetch('https://localhost:44383/User/authenticate', requestOptions)
    .then(handleResponse)
    .then(async user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      // localStorage.setItem('token', user.token);
      // localStorage.setItem('refreshToken', user.refreshToken);
      // let parsedToken = await this.parseJwt(user.token);

      // let ret = {
      //   UserId: parsedToken.UserId,
      //   Username: parsedToken.unique_name,
      //   Password: password,
      //   Token: user.access_token,
      //   LanguageId: parsedToken.LanguageId,
      //   TokenValidTime: moment(new Date()).add(10, 'm').format('YYYY-MM-DD HH:mm:ss'),
      //   KeepLoggedIn: true
      // }
      console.log("User token", user);
      Cookies.set('__session', user.token, { secure: true });

      fetch(`${process.env.REACT_APP_LOCAL_API_URL}api/FirmaInfo`, getRequestOption("GET", {}, false))
      .then(() => console.log("Subscribed to db changes."))
      .catch((err) => console.log("Subscription to db changes failed ", err));

      return user;
    })
    .catch(function (err) {
      let retVal = { error: "SERVICE_FAILED" }
      if(err !== "") retVal.error = err;
      return retVal;
    });
}

export const handleResponse = async response => {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        this.logout();
        // location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

export const loadData = async () => {

  // $.ajax({
  //     url: 'http://localhost:53777/api/home',
  //     method: 'GET',
  //     success: (result)=>{
  //         $('body').append('<div>' + result + '<div>');
  //     },
  //     error: (error)=>{
  //         console.log(error)
  //     }
  // })

  const requestOptions = {
    //"crossDomain": true,
    "method": "GET",
    "mode": "cors", // no-cors, cors, *same-origin
    "headers": {
      "content-type": "application/json; charset=utf-8",
      // "cache-control": "no-cache",
      // "Access-Control-Allow-Origin": "*",
    },
    //"credintials": "include",
    //"body": "password=" + password + "&username=" + username
    //"body": JSON.stringify({  })
  };

  // return {
  //     name: "Grogot",
  //     lastName: "Test",
  //     error: ""
  // }

  return await fetch(`${process.env.REACT_APP_LOCAL_API_URL}api/home`, requestOptions)
    .then(res => res.json())
    .then(res => console.log(res));


}

export const getDataFromDatabase = async (url, data, settings) => {
  // const jwtToken = Cookies.get('__session');
  // let session = getSession();
  // console.log("session: ", session);
  let response = await fetch(`${process.env.REACT_APP_LOCAL_API_URL}${url}`, getRequestOption(settings.method, data, true))
  if (response.ok) {
    let res = await response.json();
    return res;
  }
  else{
    return tryToGetNewToken(response, () => getDataFromDatabase(url,data));
  }
}

export const _base64ToArrayBuffer = (base64) =>{
  let _b64 = base64;
  if (_b64.startsWith("data:image/jpeg;base64,")) _b64 = _b64.substring(23);
  if (_b64.startsWith("data:image/png;base64,")) _b64 = _b64.substring(22);
  let raw = window.atob(_b64);
  let rawLength = raw.length;
  let array = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return new Blob([array]);
}

export const dataUpload = async (data) =>{
  let formData = new FormData();

  let blobData = data.Attachments.map(item => { return { blobData: ()=>_base64ToArrayBuffer(item.data), fileName: item.fileName } });
  // this._base64ToArrayBuffer(data.Attachments);
  blobData.forEach(function (item) {
    formData.append("Attachments", item.blobData, item.fileName);
  });

  // formData.append("Attachments[]", blobData);
  // data.map(item => {
  //   console.log("Data upload item : ", item);
  // })
  // formData.append("BeraterId", data.BeraterId);
  // formData.append("Subject", data.Subject);

  Object.keys(data).map(item => {
    if(item !== "Attachments"){
      Object.keys(data).map(item => formData.append(item, data[item]))
    }
    return item;
  });



  //let jwtToken = this.getJwtToken();
  const requestOptions = {
    "crossDomain": true,
    "method": "POST",
    "mode": "cors", // no-cors, cors, *same-origin
    "headers": {
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      //"Authorization": 'Bearer ' + jwtToken
    },
    //"credintials": "include",
    "body": formData
  };
  //+ "&grant_type=password"
  let response = await fetch(`${process.env.REACT_APP_LOCAL_API_URL}${data.endpoint}`, requestOptions);
  if (response.ok) {
    let res = await response.json();
    return res;
  } else return false;

}