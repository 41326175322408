import { ADD_NEW_NOTIFICATION, INITIALISE_STATE, MARK_NOTIFICATION_AS_READ, DELETE_NOTIFICATION } from "../actions/Types";
const initialState = {
    list: []
}

export default function notification (state = initialState, action){
    switch (action.type){
        case INITIALISE_STATE:
            return {...state, list: action.payload.notifications};
        case ADD_NEW_NOTIFICATION:        
            return { ...state, list: [action.payload, ...state.list] };
        case MARK_NOTIFICATION_AS_READ:
            return { ...state, list: action.payload};
        case DELETE_NOTIFICATION:
            return { ...state, list: action.payload};
        default:
            return state;
    }
}