import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import DbTasks from '../../db/DbTasks';
import { getBeraterId } from '../../helpers/HelperFunctions';
import BackdropSpinner from '../Reusable/BackdropSpinner';
import {getDataFromDatabase} from '../../db/api';
import moment from 'moment';

//Custom CSS modules
import "../../css/FirmenInfo.css";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(2),
    },
    allgemein:{
        // backgroundColor: 'red',  
        margin: '5px',
        marginTop: '0px',
        borderTop: '1px solid #1976d2',
        textAlign: 'center',
        display: 'block'
        //display: 'none'       
    },
    allgemeinCustom: {
        margin: '5px',
        marginTop: '0px',
        //borderTop: '1px solid #1976d2',
        textAlign: 'right',
        display: 'block'
    },
    allgemeinNoContent: {
        display: 'none'
    }
}));

function Allgemein() {
    const classes = useStyles();
    const [showLoading, setLoading] = useState(true);
    const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        let beraterId = getBeraterId();
        getDataFromDatabase(`api/FirmaInfo/Veranstaltungen`, { BeraterId: beraterId, Type: 1 }, { method: "POST" })
            .then(res => {
                //console.log(res);
                if (!res) {
                    setLoading(false);
                    return;
                } else {
                    res.sort((a, b) => {
                        return a.sortOrder - b.sortOrder;
                    });
                    setLoading(false);
                    if(res.length > 0) setShowContent(true);
                    res.map((item, index) => {

                        const img = item.body.substring(item.body.indexOf("<img"), item.body.lastIndexOf("</span>"), item.body.length);
                        const replacedImgStyle = img.replace('style="border-width:0px;', "class='img-event'");

                        const newBody = item.body.replace(img, replacedImgStyle);

                        document.getElementById("allgemein" + index).innerHTML = "";
                        document.getElementById("allgemein" + index).innerHTML = newBody;
                        document.getElementById("allgemein" + index).hidden = false;
                        document.getElementById("allgemein"+index+"date").textContent = item.modifiedDate !== null ? moment(item.modifiedDate).format("DD.MM.YYYY"): "";
                    });
                }
            })
        return () => {
            console.log("Running cleanup function !");
        }
    }, [])
    return (
        <div className={classes.root}>
            Allgemein

            <div className={classes.modifiedDate}>
                <span id="allgemein0date" className={showContent ? classes.allgemeinCustom : classes.allgemeinNoContent}></span>
            </div>

            <div id="allgemein0" data-testid="allgemein0" className={showContent ? classes.allgemein : classes.allgemeinNoContent}></div>
            
            <div className={classes.modifiedDate}>
                <span id="allgemein1date" className={showContent ? classes.allgemeinCustom : classes.allgemeinNoContent}></span>
            </div>

            <div id="allgemein1" className={showContent ? classes.allgemein : classes.allgemeinNoContent}></div>
            
            <BackdropSpinner showLoading={showLoading} />
        </div>
    )
}

export default withRouter(Allgemein);