import { TOGGLE_DRAWER, INITIALISE_STATE, TOGGLE_SPINNER, SW_INIT, SW_UPDATE, INSTALL_APP, SHOW_INSTALL_PROMPT, CHAT_CONNECTION, SET_URLAUB_JAHR } from "../actions/Types";
const initialState = {
    drawerOpen: false,
    version: "0.1.2.0",
    notifications: [],
    buttonLoadingEnabled: false,
    updateAvailable: true,
    installApp: false,
    alreadyInstalled: false,
    installPromptShowedTimes: 0,
    serviceWorkerInitialized: false,
    serviceWorkerUpdated: false,
    serviceWorkerRegistration: null,
    chatConnection: null,
    urlaubJahr: new Date().getFullYear()
}

export default function app (state = initialState, action) {
    switch (action.type) {
        case INITIALISE_STATE:
            return { ...state, testData: action.payload };
        case TOGGLE_DRAWER:
            return { ...state, drawerOpen: action.payload };
        case TOGGLE_SPINNER:
            return { ...state, buttonLoadingEnabled: action.payload }
        case SW_INIT:
            return {
                ...state,
                serviceWorkerInitialized: !state.serviceWorkerInitialized,
            };
        case SW_UPDATE:
            return {
                ...state,
                serviceWorkerUpdated: !state.serviceWorkerUpdated,
                serviceWorkerRegistration: action.payload,
            };
        case INSTALL_APP:
            return {
                ...state,
                alreadyInstalled: action.payload
            }
        case SHOW_INSTALL_PROMPT:
            return {
                ...state,
                installApp: action.payload
            }
        case CHAT_CONNECTION:
            return {
                ...state,
                chatConnection: action.payload
            }
        case SET_URLAUB_JAHR:
            return {
                ...state,
                urlaubJahr: action.payload
            }
        default:
            return state;
    }
}