import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';


const Notifications = (type, message) => {
    switch (type) {
        case 'info':
          NotificationManager.info("info", message);
          break;
        case 'success':
          NotificationManager.success("success", message);
          break;
        case 'warning':
          NotificationManager.warning("warning", message);
          break;
        case 'error':
          NotificationManager.error('Error message', 'Click me!', 5000, () => {
            //alert('callback');
          });
          break;
        default:
            NotificationManager.info('Info message', message);
            break;

      }    
}
export default Notifications;

