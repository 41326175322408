import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(2),
        //fontFamily: 'sans-serif',
    },
    cardRoot: {
        minWidth: 245,
        height: 245,
        display: "flex",
        alignItems: "center",
        backgroundColor: '#8992d7',
        color: '#FFFFFF',
        textTransform: 'uppercase',
        textAlign: 'left',
        fontWeight: 'bold',
        ['@media (max-width: 600px)']: {
            height: '125px !important',
        }
    },
    paper: {
        padding: theme.spacing(0),
        textAlign: 'center',
        color: theme.palette.text.secondary
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: '1em',
        textAlign: 'left',
        fontWeight: 'bold',
        ['@media (max-width: 600px)']: {
            fontSize: '0.75em',
        }
    },
    typographyMobile: {
        ['@media (max-width: 600px)']: {
            fontSize: '1.2em'
        }
    },
    pos: {
        marginBottom: 12,
    },
    green: {
        backgroundColor: '#79c350'
    },
    red: {
        backgroundColor: '#f06292'
    },
    blue: {
        backgroundColor: '#45bbdf'
    },
    rose: {
        backgroundColor: '#f5abab'
    },
    palm: {
        height: 40
    },
    circleStyle: {
        backgroundColor: '#FFFFFF',
        borderRadius: "50%",
        width: 100,
        height: 100,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        margin: 0,
        opacity: 0.3,
        position: 'absolute',
        ['@media (max-width: 600px)']: {
            width: 70,
            height: 70,
        }
    },
    circle: {
        position: 'relative',
        width: 'inherit',
        height: 'inherit',
        textAlign: 'center'
    },
    button: {
        padding: theme.spacing(2),
    },
    ravno: {
        textAlign: 'right'
    },
    iconContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 70,
        height: 70,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        ['@media (max-width: 600px)']: {
            width: 28,
            height: 28
        }
    }
}));


export default function CardContainer(props) {
    const classes = useStyles();
    return (
        <Card className={clsx(classes.cardRoot, classes[props.class || ""])} variant="outlined" data-testid="card">
            <Grid container direction="row" justify="center" alignItems="center" >
                <Grid item xs={6} sm={6} style={{ padding: '10px' }}>
                    <CardContent>
                        <Typography className={classes.title} gutterBottom data-testid="title">
                            {props.title}
                        </Typography>
                        <Typography variant="h2" className={classes.typographyMobile} data-testid="number">
                            {props.number}{props.number2 ? "/"+ props.number2 : ""}
                        </Typography>
                        <Typography className={classes.pos} data-testid="subtitle">
                            {props.subtitle}
                        </Typography>
                    </CardContent>
                </Grid>
                <Grid item xs={6} sm={6}>
                    {/* <div className={classes.circle}>
                        <div className={classes.circleStyle}>
                        </div>
                        <div className={classes.iconContainer}>
                            {props.mainIcon}
                        </div>
                    </div> */}
                    <div className={classes.circle}>
                        <div className={classes.circleStyle}>
                        </div>
                        <div className={classes.iconContainer} data-testid = "icon">
                            {props.mainIcon}
                        </div>
                        {/* <FaUmbrellaBeach size={"5em"} color="white"/> */}
                    </div>
                </Grid>
            </Grid>
            {/* <CardActions>
                <Button size="small">Learn More</Button>
            </CardActions> */}
        </Card>
    )
}