import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import DbTasks from '../../db/DbTasks';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Card } from '@material-ui/core';
import { FaInfoCircle } from 'react-icons/fa';
import { getDataFromDatabase } from '../../db/api';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(2),
        //width: '100%',
    },
    cardRoot: {
        minWidth: 245,
        height: 'auto',
        //margin: theme.spacing(1),
        backgroundColor: '#FFFFFF',
        color: '#FFFFFF',
        textTransform: 'uppercase',
        textAlign: 'left',
        fontWeight: 'bold',
        margin: theme.spacing(1),      

    },
    item:{
        //backgroundColor: '#45bbdf',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 'bold',
    },
    accStil:{
        //backgroundColor: '#45bbdf',
        color: 'grey',
        // border: '1px solid grey'
    },
    answer:{
        textAlign: 'justify',
        fontFamily: 'Fira Sans, Sans-serif',
        fontSize: '1.2em',
        textTransform: 'initial'
        //border: '1px solid grey'
    },
    link:{
        color:'#5b8def',
        textDecoration:'underline'
    }
}));

function WegweisserAnswers(props) {
    const classes = useStyles();
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        let id = parseInt(props.match.params.id);
        getDataFromDatabase(`api/Wegweisser/WegAnswers?subGroupId=${id}`, {}, { method: "GET" })
            .then(res => {
                setGroups(res);
            });
        return () => {
            console.log("Running cleanup function !");
        }
    }, [props.match.params.id]);

    const isValidHttpUrl = (string, open) => {
        let url;
        
        try {
          url = new URL(string);
        } catch (_) {
          return false;  
        }
      
        //return url.protocol === "http:" || url.protocol === "https:";
        if(open==true)
        {
            window.open(string);
        }
        return true;
    }

    return (
        <div className={classes.root}>

            <Grid container spacing={1} alignContent='center' justify='center'>
                <Grid item lg={6} md={8} sm={10} xs={12} className={classes.item}>
                        {groups.length > 0 && groups.map(el =>
                            // <>
                            <Card className={classes.cardRoot} key={el.id} variant="outlined">
                                <Accordion key={el.id} className={classes.accStil}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className={`${classes.heading} ${isValidHttpUrl(el.question, false) ? classes.link : classes.heading}`} onClick={()=>isValidHttpUrl(el.question, true)}>{el.question}</Typography>
                                        <div style={{textAlign: 'right', flexGrow: 1, fontSize: '1.5em'}}>
                                        <FaInfoCircle/>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography className={classes.answer}>
                                            {el.answer}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                </Card>
                            // </>
                        )}
                </Grid>
            </Grid>
        </div>

    )
}

export default withRouter(WegweisserAnswers);