import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import DbTasks from '../../db/DbTasks';
import { addMessagesToList, initiateMesageList } from '../../actions/ChatActions';
import BackdropSpinner from '../Reusable/BackdropSpinner';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import { Divider, Grid, IconButton, List } from '@material-ui/core';
import moment from 'moment-with-locales-es6';
import Typography from '@material-ui/core/Typography'

import stylesChat from './Chat.module.css';
import { getDataFromDatabase } from '../../db/api';
import { getBeraterId } from '../../helpers/HelperFunctions';

export let util = {sendMessage:null};
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        //maxWidth: '36ch',
        height: '120%',
        backgroundColor: theme.palette.background.paper,
    },
    rootInput: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        justify: 'center',
        minWidth: 300,
        maxWidth: 400,
        position: 'fixed',
        left: '50%',
        transform: 'translate(-50%, 0)',
        bottom: 10,
        border: '1px solid black',
    },
    inline: {
        display: 'inline',
    },
    myMessages: {
        backgroundColor: '#dedede',
        textAlign: 'right',
        marginTop: '5px',
        borderRadius: '5px',
        // maxWidth: '70%'
    },
    friendMessages: {
        backgroundColor: '#83acd6',
        textAlign: 'left',
        marginTop: '5px',
        borderRadius: '5px',
        color: '#ffffff'
        // maxWidth: '70%'
    },
    alignRight: {
        marginRight: 0,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    noMessagesInfo:{
        alignSelf: 'center'
    }
}));

const messageIconPath = "M19.5,2.1c0.7-0.2,1.4,0,1.9,0.5c0.5,0.5,0.7,1.3,0.5,1.9l-1.2,4.2c-0.1,0.4-0.5,0.6-0.9,0.5c-0.4-0.1-0.6-0.5-0.5-0.9l1.2-4.2c0.1-0.2-0.1-0.4-0.1-0.5c-0.1-0.1-0.2-0.2-0.4-0.1L3.8,8.2C3.6,8.3,3.5,8.5,3.5,8.6c0,0.1,0,0.3,0.2,0.5l3.4,2.1c0.4,0.2,0.5,0.7,0.2,1c-0.1,0.2-0.4,0.4-0.6,0.4c-0.1,0-0.3,0-0.4-0.1l-3.4-2.1C2.3,9.9,1.9,9.2,2,8.4C2.1,7.6,2.7,7,3.4,6.8L19.5,2.1z M18,12.3c0.1-0.4,0.5-0.6,0.9-0.5c0.4,0.1,0.6,0.5,0.5,0.9l-2.3,7.8c-0.2,0.8-0.8,1.3-1.6,1.4c-0.1,0-0.2,0-0.3,0c-0.7,0-1.3-0.3-1.7-0.9l-4.1-6.8c-0.2-0.3-0.1-0.7,0.1-0.9l5.8-5.9c0.3-0.3,0.8-0.3,1.1,0c0.3,0.3,0.3,0.8,0,1.1L11.1,14l3.8,6.3c0.1,0.2,0.4,0.2,0.4,0.2c0.1,0,0.3-0.1,0.4-0.3L18,12.3z"

function GroupChat(props) {
       const classes = useStyles();
       const messageList = useSelector(state => state.chat.messageList) || [];
       const [showLoading, setLoading] = useState(true);
       const dispatch = useDispatch();
       const messageListEl = useRef(null);
       const [dates,setDates] = useState([]);
       let dts = []; //array that stores array of message times
       let uqdts = []; //array that stores unique array of message times, unique message separators

       const chatConnection = useSelector(state => state.app.chatConnection);
       const groupId = props.match.params.groupId;
       console.log("Chat connection : ", chatConnection);

    useEffect(() => {

        chatConnection && chatConnection.on("ReceiveGroupMessage", function (user, message) {
            getPreviousMessages();
            //alert("uslo u recieve message");
            console.log("chat user and message : ", user, message);
            //if (user === parseInt(friendUserId)) {
                console.log("Current message list  : ", messageList);
                //addMessage({ byMe: false, message });
                let messageToAdd = { byMe: false, id: Math.random().toString(), message: message }
                dispatch(addMessagesToList(messageToAdd));
                 //console.log("recieve message -uqdts",uqdts);
                 //console.log("recieve message -dates",dates);
                 let today = moment(new Date()).format("DD.MM.YYYY").toString();
                 if(uqdts.includes(today)==false) //if there is no messages for today, add today to message separators
                 {
                     setDates(prevState => (
                         [...prevState, today]
                       ))
                 }
                 scrollToBottom();
        });
        console.log("Running api : ", messageList.length);


        return () => {
            console.log("Running cleanup function !");
        }
    },
        [chatConnection]
    );

    const scrollToBottom = (e) => {
        //e.preventDefault();
        let messageEl = document.getElementById("messageListContainer");
        let scrlHeight = messageEl.scrollHeight;
        messageEl.scrollTo({ left: 0, top: scrlHeight, behaviour: "smooth" })
    }

    useEffect(() => {
        scrollToBottom();
    }, [messageList])

//     //function for getting all previous messages between 2 users (getting chat history)
    const getPreviousMessages = () =>{
        //part of code which handles regular chat messages
            getDataFromDatabase(`api/Chat/GetChatGroupsHistory?groupId=${groupId}`, {}, { method: "GET" })
                .then(res => {
                    console.log("res group messages", res);
                    res.messages?.map(item=>{
                        dts.push(moment(item.date).format("DD.MM.YYYY"));//store all datetimes of the messages
                    });
                    uqdts = [...new Set(dts)];//store only unique datetimes of the messages
                    setDates(uqdts);
                    dispatch(initiateMesageList(res));
                    setLoading(false);
                    console.log("uqdts",uqdts)
                    scrollToBottom();
                })
                .catch((error) => {
                    setLoading(false);
                    alert("Error happened while getting the chat history.");

                });
    }


    useEffect(() => {
        //get chat history at the beginning
        getPreviousMessages();
        return () => {
            console.log("Clearing timeout");
            // clearTimeout(scrollAfter);
        }

    }, [])

 util.sendMessage = () => {

            let localMessage = document.getElementById("chatTypedMessage").value || "";
            if (localMessage === "" || localMessage === "\n") return;

            new DbTasks().getDataFromDb(`api/Chat/SendMessageToGroup`, { IDGroup: parseInt(groupId), message: localMessage }, { method: "POST" })
                .then(res => {
                    console.log("Send message res : ", res);
                    //setMessageList(res);
                });
            addMessage({ byMe: true, message: localMessage });
            document.getElementById("chatTypedMessage").value = "";

            let today = moment(new Date()).format("DD.MM.YYYY").toString();
            if(dates.includes(today)==false) //if there is no messages for today, add today to message separators
            {
                setDates(prevState => (
                    [...prevState, today]
                ))
            }
            scrollToBottom();
    }

    const addMessage = (data) => {
        console.log("Add message list: ", messageList);
        let messageToAdd = { byMe: data.byMe, id: messageList.length + 1, message: data.message }
        dispatch(addMessagesToList(messageToAdd));
        scrollToBottom();
    }

    const Message = ({ id, message, by, time, date, beraterName }) => {
        return (
            <>
            {by==true?"":<p style={{color:"grey", fontWeight:"bold", marginBottom:"1px",marginLeft:"0px",marginRight:"0px",marginTop:"5px", fontSize:"13px", textAlign:"left"}}>{beraterName}</p>}
                <li key={id} className={`${stylesChat.messageItem} ${by ? stylesChat.byMe : stylesChat.byFriend}`}>
                    <span key={id} className={stylesChat.messageText}>{message}</span>
                    <span key={id} className={`${by ? stylesChat.byMeTime : stylesChat.byFriendTime}`}>{moment(date).format("HH:mm")}</span>
                </li>
            </>
        )
    }

    const MessageField = () => {

        return (
            <>
                <div className={stylesChat.messageField}>
                    <textarea
                        data-testid="text-area"
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                e.preventDefault();
                                util.sendMessage()
                            }
                        }}
                        name=""
                        id="chatTypedMessage"
                        placeholder="Schreibe Deinen Text..." spellCheck={false}></textarea>
                    <button
                        data-testid="send-message"
                        onClick={() => util.sendMessage()}
                        className={`btn btn-small ${stylesChat.btnSend}`}>
                        <svg version="1.1" width="25px" height="21px" x="0px" y="0px">
                            <path d={messageIconPath} />
                        </svg>
                    </button>
                </div>
            </>
        )
    }

    return (
        <>
            <div className="chatBody">
                <div className={stylesChat.chatWrapper}>
                    <ul className={stylesChat.messagesList} id="messageListContainer" ref={messageListEl}>
                        {messageList.length === 0 && <li className={classes.noMessagesInfo}>
                            <div>
                            Hier noch keine Nachricht. <br></br>
                            Nachricht senden mit dem <span><svg version="1.1" width="25px" height="21px" x="0px" y="0px">
                                <path d={messageIconPath} />
                            </svg></span>
                            </div>
                           
                        </li>}
                        {dates.length>0 && dates.map((item,index)=>{
                             return (
                              <React.Fragment key={index}>
                                  <Typography key={index} className={stylesChat.messageSeparator} variant="subtitle1">{item}</Typography>
                                    {messageList.length > 0 && messageList.map(msg => {
                                    const { id, initials, message, byMe, time, date, beraterName } = msg
                                    return (
                                        item == moment(date).format("DD.MM.YYYY") ? <Message key={id} initials={initials} message={message} by={byMe} time={time} date={date} beraterName={beraterName}/> : ''
                                    )
                                })}
                             </React.Fragment>
                             )
                        })}
                        
                    </ul>
                    <MessageField />
                </div>
            </div>
            <BackdropSpinner showLoading={showLoading} />
            {/* <h1>group chat</h1> */}
        </>
    );
}

export default withRouter(GroupChat);