import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import DbTasks from '../../db/DbTasks';
import { getBeraterId } from '../../helpers/HelperFunctions';
import BackdropSpinner from '../Reusable/BackdropSpinner';
import {getDataFromDatabase} from '../../db/api';

//Custom CSS modules
import "../../css/FirmenInfo.css";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(2),
    },
    veran: {
        margin: '5px',
        marginTop: '0px',
        borderTop: '1px solid #1976d2',
        textAlign: 'center',
        display: 'block'
    },
    veranCustom: {
        margin: '5px',
        marginTop: '0px',
        //borderTop: '1px solid #1976d2',
        textAlign: 'right',
        display: 'block'
    },
    veranNoContent: {
        display: 'none'
    },
    modifiedDate: {
        textAlign: 'right'
    }
}));

function Veranstaltungen() {
    const classes = useStyles();
    const [showLoading, setLoading] = useState(true);
    const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        let beraterId = getBeraterId();
        let view = window.location.pathname === "/mitarbeiterhandbuch" ? 'api/FirmaInfo/Veranstaltungen' : 'api/FirmaInfo/Veranstaltungen' //if view is Veranstaltungen, then we use controller just for Veranstaltungen data
        let eventType = window.location.pathname === "/mitarbeiterhandbuch" ? 2 : 0; // 2 - stands for Mitarbeiterhandbuch type of event in Suite. 0 is for private events / Veranstaltungen
        getDataFromDatabase(view, { BeraterId: beraterId, Type: eventType }, { method: "POST" })
            .then(res => {
                console.log(res);
                if (!res) {
                    setLoading(false);
                    return;
                } else {
                    res.sort((a, b) => {
                        return a.sortOrder - b.sortOrder;
                    });
                    setLoading(false);
                    if (res.length > 0) setShowContent(true);
                    res.map((item, index) => {
                        
                        const img = item.body.substring(item.body.indexOf("<img"), item.body.lastIndexOf("</span>"), item.body.length);
                        const replacedImgStyle = img.replace('style="border-width:0px;', "class='img-event'");

                        const newBody = item.body.replace(img, replacedImgStyle);

                        document.getElementById("veran" + index).innerHTML = "";
                        document.getElementById("veran" + index).innerHTML = newBody;
                        document.getElementById("veran" + index).hidden = false;
                        document.getElementById("veran" + index + "date").textContent = item.modifiedDate !== null ? moment(item.modifiedDate).format("DD.MM.YYYY"): "";
                    });
                    //document.getElementById("body").innerHTML = res.length > 0 ? res[0].body : "No data for Veranstaltungen.";
                }
            })
        return () => {
            console.log("Running cleanup function !");
        }
    }, [])
    return (
        <div className={classes.root}>
            <div className={classes.modifiedDate}>
                <span id="veran0date" className={showContent ? classes.veranCustom : classes.veranNoContent}></span>
            </div>

            <div id="veran0" data-testid="veran0" className={showContent ? classes.veran : classes.veranNoContent}>

            </div>
            <div className={classes.modifiedDate}>
                <span id="veran1date" className={showContent ? classes.veranCustom : classes.veranNoContent}></span>
            </div>
            <div id="veran1" className={showContent ? classes.veran : classes.veranNoContent}>

            </div>
            <BackdropSpinner showLoading={showLoading} />
        </div>
    )
}

export default withRouter(Veranstaltungen);