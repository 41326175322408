import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getSession } from '../helpers/HelperFunctions';

export const PrivateRoute = ({ component: Component, ...rest }) => (
    
    <Route {...rest} render={props => (
    // <Component {...props} />
        getSession() ? <Component {...props} /> : <Redirect to = {{ pathname: '/'}} />
    )} />
)