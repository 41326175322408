import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import DbTasks from '../../db/DbTasks';
import { makeStyles } from '@material-ui/core/styles';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import BackdropSpinner from '../Reusable/BackdropSpinner';
import { FaDownload, FaExpandArrowsAlt, FaImage } from 'react-icons/fa';
import { Grid } from '@material-ui/core';
import moment from 'moment-with-locales-es6';
import {getDataFromDatabase} from '../../db/api';

export let util = {download: null };

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // flexWrap: 'wrap',
    // justifyContent: 'space-around',
    // overflow: 'hidden',
    // backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
    margin: theme.spacing(2),
  },
  cardRoot: {
    minWidth: 245,
    height: 245,
    listStyleType: 'none'
  },
  gridList: {
    width: 'auto',
    height: 'auto',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  cardText: {
    textAlign: 'left',
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function GalleryPreview(props) {
  const classes = useStyles();
  const [imageList, setImageList] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showLoading, setLoading] = useState(true);

  useEffect(() => {
    let id = parseInt(props.match.params.id);
    let folderId = parseInt(props.match.params.folderId);
    getDataFromDatabase(`api/Gallery/GetMitarbeiterImageList?FolderId=${folderId}&id=${id}`, {}, { method: "GET" })
      .then(res => {
        res.sort((a, b) => (a.datum < b.datum) ? 1 : -1)
        setImageList(res);
        setLoading(false);
      });
      let month = id == 0 ? moment().month(0).locale('de').format("MMMM") : moment().month(id-1).locale('de').format("MMMM");
      document.getElementById("app-bar-title").innerHTML = `Gallerie - Fotos hochgeladen von Mitarbeiter - ${month}`;
    return () => {
      console.log("Running cleanup function !");
    }
  }, []);

   util.download = (id, fullPreview = false) => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_LOCAL_API_URL}api/Gallery/Download?id=${id}`, { method: "GET" })
      .then(response => response.blob())
      .then(blob => {
        if (fullPreview) {
          //Pretvoriti blob u base64 i otvoriti sliku u dialog-u
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            var base64data = reader.result;
            setDialogOpen(true);
            let windowHeight = window.innerHeight -110;
            //alert(windowHeight);
            document.getElementById("fullSizeImage").innerHTML = `<img id="imgFull" style="width:100%;" src="${base64data}" alt="full preview" />`;
          }
        } else {
          //pripremiti blob file za download
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = "bild.jpg";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove();  //afterwards we remove the element again  
        }
        setLoading(false);
      }     
      );
  }

  return (
    <div>
      {/* Gallery preview */}
      <div className={classes.root}>
        <Grid container spacing={1} alignContent='center' justify='center' data-testid="grid-content">
          {/* <GridList cellHeight={180} className={classes.gridList}> */}
          {imageList.map((tile) => (
            <Grid item lg={3} md={4} sm={6} xs={12} key={tile.id}>
              <GridListTile key={tile.id} className={classes.cardRoot}>
                {/* <img src={`data:image/jpeg;base64,${tile.base64}`} alt={tile.imageName} /> */}
                {tile.base64 !== null ? <img src={`data:image/${tile.extension};base64,${tile.base64}`} style={{ width: "100%" }} alt={"Gallery"} /> :
                  <div style={{ width: "100%", height: "100%" }}><FaImage size={"5em"} color="grey" /></div>}
                <GridListTileBar
                  className={classes.cardText}
                  // title={tile.imageName}
                //   subtitle={tile.datum ? <span>Created: {moment(tile.datum).format("DD.MM.YYYY")}</span> : ''}
                  key={tile.id}
                  actionIcon={
                    <div>
                      <IconButton onClick={() => util.download(tile.id, true)} aria-label={`info about tile.title`} className={classes.icon} data-testid={`expand-${tile.id}`}>
                        <FaExpandArrowsAlt size={"1.5em"} color="white" />
                      </IconButton>
                      {/* <IconButton onClick={() => util.download(tile.id)} aria-label={`info about tile.title`} className={classes.icon} data-testid={`download-${tile.id}`}>
                        <FaDownload size={"1.5em"} color="white" />
                      </IconButton> */}
                    </div>
                  }
                />
              </GridListTile>
            </Grid>
          ))}
          {/* </GridList> */}
        </Grid>
      </div>
      <Dialog
        fullScreen
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        //onClose={props.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{props.title}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-slide-description">
            FullSize preview
          </DialogContentText> */}
          <div id="fullSizeImage">

          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            document.getElementById("imgFull").remove();
            setDialogOpen(false);
          }} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <BackdropSpinner showLoading={showLoading} />
    </div>
  )
}

export default withRouter(GalleryPreview);