import {login} from '../db/api';
import { LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT, LOGIN_REQUEST } from '../actions/Types';

export const userLogin = async(username, password, settings) => {
    const user = await login(username, password, settings);
    //console.log("User actions : ", user);
    if(user.error && user.error !== "") return false;
    else return user;
    // return dispatch => {
    //     return LOGIN_SUCCESS(user)
    // };

    // function request(user) { return { type: LOGIN_REQUEST, user}}
    // function success(user) { return { type: LOGIN_SUCCESS, user}}
    // function failure(user) { return { type: LOGIN_FAILURE, user}}
}