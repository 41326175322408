import React from 'react'
import { Button } from '@material-ui/core';
import './custom.css'

const Modal = props =>{

    return(
        <div className={`modal ${props.show ? 'show' : ''}`}  onClick={props.onClose}>
            <div className='modal-content' onClick={e=>e.stopPropagation()}>
                <div className='modal-header'>
                    <h4 className='modal-title'>{props.title}</h4>
                </div>
                <div className='modal-body'>
                    {props.children}
                </div>
                <div className='modal-footer'>
                    <Button className='close-btn' style={{backgroundColor:"#2074d4",color:"white", fontWeight:"700",margin:"1%"}} onClick={props.save}>Save</Button>
                    <Button className='close-btn' style={{backgroundColor:"#2074d4",color:"white", fontWeight:"700",margin:"1%"}} onClick={props.onClose}>Close</Button>
                </div>
            </div>
        </div>
    )
}

export default Modal;