import React from 'react';
import {
  withStyles,
} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const CssTextField = withStyles({
  root: {
    backgroundColor: '#fcfcfb',
    borderRadius: '4px',
    '& label.Mui-focused': {
      color: '#58585a',
      marginTop: '-4px'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#a8a9ad',
      },
    //   '&:hover fieldset': {
    //     borderColor: 'yellow',
    //   },
      '&.Mui-focused fieldset': {
        borderColor: '#58585a',
      },
    },
  },
})(TextField);

export default function TextFields(props) {
//console.log("Text field props :", props);
  return (
      <CssTextField 
      error={props.error}
      onChange={props.onChange} 
      value={props.value} 
      id={props.id} 
      label={props.label} 
      type={props.type} 
      variant={props.variant} 
      className={props.class} 
      fullWidth={props.fullWidth}
      disabled={props.disabled}
      autoComplete='off'
      onKeyPress={props.onKeyPress}
      InputProps={props.InputProps}
      />
  );
}
