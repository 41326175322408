import DbTasks from '../db/DbTasks';
import { ADD_NEW_NOTIFICATION, MARK_NOTIFICATION_AS_READ, DELETE_NOTIFICATION, INITIALISE_STATE } from '../actions/Types';

export const addNewNotification = (param) => async dispatch => {
    console.log("Add new notification action : ");
    let data = await new DbTasks().addNotification(param);
    dispatch({
        type: ADD_NEW_NOTIFICATION,
        payload: data
    });
}

export const markAsRead = (param) => async dispatch => {
    let data = await new DbTasks().markNotificationAsRead(param);
    if (data.length === 0) { console.log("Notification update went wrong !"); return; }
    dispatch({
        type: MARK_NOTIFICATION_AS_READ,
        payload: data
    })
}

export const removeNotification = (param) => async dispatch =>{
    let data = await new DbTasks().deleteNotification(param);
    if(data.length === 0){console.log("Notification update went wrong !"); return; }
    dispatch({
        type: DELETE_NOTIFICATION,
        payload: data
    })
}

export const initializeNotification = (data) => async dispatch =>{
    dispatch({
        type: INITIALISE_STATE,
        payload: data
    })
}

// function  test(param){
//     return async function(dispatch){
//         dispatch({

//         })
//     }
// }