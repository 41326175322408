import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode";

import abwesendAbwesend from "../../src/assets/icons/abwesendAbwesend.png";
import abwesendHome from "../../src/assets/icons/abwesendHome.png";
import abwesendKurzarbeit from "../../src/assets/icons/abwesendKurzarbeit.png";
import abwesendLehrgang from "../../src/assets/icons/abwesendLehrgang.png";
import abwesendPrufung from "../../src/assets/icons/abwesendPrufung.png";
import abwesendPrufungsV from "../../src/assets/icons/abwesendPrufungsV.png";
import abwesendSchule from "../../src/assets/icons/abwesendSchule.png";
import abwesendUberstunden from "../../src/assets/icons/abwesendUberstunden.png";
import urbaub_icon from "../../src/assets/icons/add_urlaub.png";
import elternzeit from "../../src/assets/icons/elternzeit.png";
import add_feiertag from "../../src/assets/icons/add_feiertag.png";
import add_krank from "../../src/assets/icons/add_krank.png";
import uberstunden from "../../src/assets/icons/uberstunden.png";

export const getSession = () => {
    const jwt = Cookies.get('__session')
    let session
    try {
      if (jwt) {
        const base64Url = jwt.split('.')[1]
        const base64 = base64Url.replace('-', '+').replace('_', '/')
        // what is window.atob ?
        // https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/atob
        session = jwt_decode(jwt);
      }
    } catch (error) {
      console.log(error)
    }
    return session
  }
  export const logOut = () => {
    Cookies.remove('__session');
    window.location.reload();
  }

  export const getBeraterId = () => {
    let cookie = getSession();
    if(cookie!=null)
    return parseInt(cookie.nameid);
  }

  export const getBeraterName = () => {
    let cookie = getSession();
    //console.log("cokie",cookie);
    return cookie.unique_name;
  }

  export const imageToDataUri = (img, width, height, extension) => {

    // create an off-screen canvas
    var canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');

    // set its dimension to target size
    canvas.width = width;
    canvas.height = height;

    // draw source image into the off-screen canvas:
    ctx.drawImage(img, 0, 0, width, height);

    // encode image to data-uri with base64 version of compressed image
    if (extension === ".png") {
        return canvas.toDataURL('image/png', 0.2);
    } else {
        return canvas.toDataURL('image/jpeg', 0.2);
    }

}

export const getBase64 = file => {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
          const img = new Image();
          img.src = reader.result;
          img.onload = () => {
              let extension = img.src.startsWith('data:image/png') === true ? ".png" : ".jpg";
              var newDataUri = imageToDataUri(img, img.width, img.height, extension);
              resolve(newDataUri);
          };

          reader.onerror = error => reject(error);
      };
      reader.onerror = error => reject(error);
  });
};

export const handleUrlaubIcon = (type, urlaub_type, urlaub_subtype) =>{
  if(type == 4)//if type="Urlaub"
  {
    if(urlaub_subtype == 10)
      return elternzeit;
    else return urbaub_icon;
  }
  else if(type==5)
    return add_krank;
  else if(type==3)
    return add_feiertag;
  else if(type==7 || type == 11)
    return uberstunden;
  else
  {
    if((type==6 && urlaub_type!=null) || (type==0 && urlaub_type==9))
    {
      switch(urlaub_type)
      {
        case 9: return abwesendHome;
        case 6: return abwesendSchule;
        case 2: return abwesendAbwesend;
        case 3: return abwesendLehrgang;
        case 4: return abwesendPrufungsV;
        case 5: return abwesendPrufung;
        case 10: return abwesendKurzarbeit;
        case 8: return abwesendUberstunden;
      }
    }
  }
  return "";
}