import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import Dialog from '../Reusable/Dialog';
import moment from 'moment';
import DbTasks from '../../db/DbTasks';
import { FaUmbrellaBeach, FaAmbulance } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import { getBeraterId } from '../../helpers/HelperFunctions';
import CardContainer from '../Reusable/CardContainer';
import BackdropSpinner from '../Reusable/BackdropSpinner';
import {getDataFromDatabase} from '../../db/api'; 
import { useDispatch } from 'react-redux';
import { setUrlaubJahr } from '../../actions/AppActions';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(2),
        //fontFamily: 'sans-serif',
    },
    cardRoot: {
        minWidth: 245,
        height: 245,
        backgroundColor: '#8992d7',
        color: '#FFFFFF',
        textTransform: 'uppercase',
        textAlign: 'left',
        fontWeight: 'bold',
        ['@media (max-width: 600px)']: {
            height: '115px !important',
        }
    },
    paper: {
        padding: theme.spacing(0),
        textAlign: 'center',
        color: theme.palette.text.secondary
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: '1em',
        textAlign: 'left',
        fontWeight: 'bold'

    },
    pos: {
        marginBottom: 12,
    },
    green: {
        backgroundColor: '#79c350'
    },
    red: {
        backgroundColor: '#f06292'
    },
    blue: {
        backgroundColor: '#45bbdf'
    },
    palm: {
        height: 40
    },
    circleStyle: {
        marginTop: 0,
        marginRight: '0%',
        marginLeft: 0,
        display: "inline-block",
        backgroundColor: '#FFFFFF',
        borderRadius: "50%",
        width: 100,
        height: 100,
        right: 0,
        top: 0,
        opacity: 0.3,
        position: 'absolute',
    },
    circle: {
        position: 'relative',
        textAlign: 'right',
        marginRight: '20%'
    },
    button: {
        height: '100%',
        width: '100%'
    },
    ravno: {
        textAlign: 'right'
    }
}));


function Urlaub(props) {
    const classes = useStyles();
    const [localState, setLocalState] = useState({
        totalVacationDays: 0,
        availableDays: 0,
        usedDays: 0,
        overtimeHours: 0,
        requested: 0,
        planned: 0,
        krankDays: 0,
        showLoader: true,
    });

    // let [plannedDays, setPlannedDays] = useState(0);
    // let [availableDays, setAvailableDays] = useState(0);
    // let [usedDays, setUsedDays] = useState(14);
    // let [overtimeHours, setOvertime] = useState(54);

    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [dateRange, setDateRange] = useState([new Date(), new Date()]);

    const [jahr, setJahr] = useState(new Date().getFullYear());

    const handleDateChange = (date) => {
        if (date === null) setDateRange([new Date(), new Date()]);
        else setDateRange(date);
    };

    let beraterId = getBeraterId();

    const requestVacation = () => {
        console.log("Send request to server.");
        new DbTasks().getDataFromDb("api/Urlaub/RequestUrlaub", {
            beraterId: beraterId,
            startDate: new Date(new Date(dateRange[0]).setHours(7)),
            endDate: new Date(new Date(dateRange[1]).setHours(7)),
        },
            { method: "POST" })
            .then(res => {
                console.log("Request res", res);
                if(res) setOpen(false);
            })
    }

    const renderUrlaubYear = async (urlaubJahr) => {
        await getDataFromDatabase("api/Urlaub/GetUrlaubInfo", { beraterId: beraterId, year: urlaubJahr }, { method: "POST" })
            .then(res => {
                console.log("response from server", res);
                // setAvailableDays(res.previousYearRest + res.currentYearAvailableDays - res.usedThisYear);
                var totalAvailable = res.previousYearRest + res.currentYearAvailableDays - res.usedThisYear
                // setPlannedDays(res.currentYearAvailableDays);
                // setUsedDays(res.usedThisYear);
                // setOvertime(res.overtimeHours);

                setLocalState({
                    ...localState,
                    availableDays: totalAvailable,
                    totalVacationDays: res.currentYearAvailableDays + res.allUnusedDays, //ukupno predviđeno za trenutnu godinu + prenos iz prošle
                    usedDays: res.usedDates.length + res.betriebRuhe.length,
                    planned: res.plannedDates.length,
                    requested: res.urlaubRequest.length,
                    overtimeHours: res.overtimeHours,
                    krankDays: res.krankDatesCount,
                    showLoader: false,
                })
            }).catch(() => {
                setLocalState({...localState, showLoader: false});
                alert("Error happened while retrieving data.");
            })
    }

    useEffect(async() => {
        const location = window.location.pathname;

        if (location.lastIndexOf("/") !== 0) {
            location = "/" + location.slice(1, location.indexOf("/", 1) + 1);
        }

        const currentYear = new Date().getFullYear();

        switch (location) {
            case "/urlaubDiesesJahr":
                {
                    if (jahr !== currentYear) {
                            setJahr(async(prev)=>{
                                await renderUrlaubYear(currentYear);
                                const newDateRange = [new Date(currentYear, 0, 1), new Date(currentYear, 0, 1)]
                                setDateRange(newDateRange);
                                dispatch(setUrlaubJahr(currentYear));
                                return currentYear;
                            });
                        }
                        else{
                           await renderUrlaubYear(jahr);
                           dispatch(setUrlaubJahr(jahr))
                        }
                }
                break;
            case "/urlaubNaechstesJahr":
                {
                    if (jahr !== currentYear + 1) {
                            setJahr(async(prev)=>{
                                await renderUrlaubYear(currentYear + 1);
                                const newDateRange = [new Date(currentYear + 1, 0, 1), new Date(currentYear + 1, 0, 1)]
                                setDateRange(newDateRange);
                                dispatch(setUrlaubJahr(currentYear + 1));
                                return currentYear + 1;
                            });
                        }
                        else{
                            await renderUrlaubYear(jahr);
                            dispatch(setUrlaubJahr(jahr))
                        }
                }
                break;
        }
        
        return () => {
            console.log("Running cleanup function !");
        }
    }, []);

    return (
        <div className={classes.root} >
            <Grid container spacing={1} justify='center' alignContent='center'>
                <Grid item xs={12}>
                <Grid container spacing={1} justify='center' alignContent='center'>
                <Grid item xs={12} sm={6} md={4} lg={3} className={classes.ravno} data-testid="date-range-picker" >
                    <DateRangePicker
                        className={classes.button}
                        onChange={handleDateChange}
                        value={dateRange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Button
                        data-testid="urlaub-anfragen"
                        onClick={() => setOpen(true)}
                        variant="contained"
                        color="primary"
                        fullWidth
                        className={classes.button}
                        endIcon={<FaUmbrellaBeach />}
                        size="large">Urlaub anfragen</Button>

                </Grid>
                </Grid>
                </Grid>
                {/* </Grid> */}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Paper className={classes.paper} data-testid="jahresurlaub-gesamt">
                        <CardContainer
                                title="Jahresurlaub Gesamt"
                                number={localState.totalVacationDays}
                                subtitle={"urlaub"}
                                class={""}
                                mainIcon={<FaUmbrellaBeach size={"4em"} color="white" />}
                            />

                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                <Paper className={classes.paper} data-testid="urlaubstage-verfugbar">
                    <CardContainer
                                title="Urlaubstage Verfügbar"
                                number={localState.totalVacationDays - localState.usedDays}
                                subtitle={"urlaub"}
                                class={"green"}
                                mainIcon={<FaUmbrellaBeach size={"4em"} color="white" />}
                            />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                <Paper className={classes.paper} onClick={() => props.history.push("/urlauboverview") } style={{cursor: 'grab'}} data-testid="urlaubstage-genommen">
                    <CardContainer
                                title="Urlaubstage genommen"
                                number={localState.usedDays}
                                subtitle={"urlaub"}
                                class={"red"}
                                mainIcon={<FaUmbrellaBeach size={"4em"} color="white" />}
                            />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Paper className={classes.paper} onClick={() => props.history.push("/urlauboverviewplanned")} style={{cursor: 'grab'}} data-testid="urlaubstage-verplant">
                    <CardContainer
                                title="Urlaubstage verplant"
                                number={localState.planned}
                                subtitle={"urlaub"}
                                class={"red"}
                                mainIcon={<FaUmbrellaBeach size={"4em"} color="white" />}
                            />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Paper className={classes.paper} data-testid="uberstunden">
                    <CardContainer
                                title="Überstunden"
                                number={localState.overtimeHours}
                                subtitle={"hours"}
                                class={"blue"}
                                mainIcon={<FaUmbrellaBeach size={"4em"} color="white" />}
                            />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Paper className={classes.paper} data-testid="krankentage">
                    <CardContainer
                                title="Krankentage"
                                number={localState.krankDays}
                                subtitle={"tage"}
                                class={"rose"}
                                mainIcon={<FaAmbulance size={"4em"} color="white" />}
                            />
                    </Paper>
                </Grid>
            </Grid>
            <Dialog
                open={open}
                keepMounted
                title={"Request vacation confirmation"}
                description={`You've selected dates
                from  ${moment(dateRange[0]).format("DD.MM.YYYY")}
                to:  ${moment(dateRange[1]).format("DD.MM.YYYY")}`}
                onClose={() => setOpen(false)}
                handleSubmit={requestVacation}
            />
            <BackdropSpinner showLoading={localState.showLoader} />
        </div>
    );
}

export default withRouter(Urlaub);