import Dexie from 'dexie';
import Cookies from 'js-cookie';
import { getSession, logOut } from '../helpers/HelperFunctions';
import 'whatwg-fetch'
export default class DbTasks {
  constructor() {
    this.db = new Dexie("mitarbeiterApp");

    this.db.version(1).stores({ notifications: '++id' });
    this.db.version(2).stores({ notifications: '++id, seen'});
    this.db.version(3).stores({ notifications: '++id, seen, text'});
  }

  getRequestOption = (method, data, useContentType) => {
    const jwt = Cookies.get('__session');
    const requestOption = {
      "crossDomain": true,
      "method": method,
      "mode": "cors", // no-cors, cors, *same-origin
      "headers": {
        "cache-control": "no-cache",
        "Access-Control-Allow-Origin": "*",
        "Authorization": 'Bearer ' + jwt
      },
      "credintials": "include",
      // "body": JSON.stringify({ ...data })
    }
    if (useContentType) requestOption.headers["content-type"] = "application/json; charset=utf-8";
    if (method === "POST") requestOption.body = JSON.stringify({ ...data });
    return requestOption;
  };

  login = async (username, password, settings) => {
    const jwt = Cookies.get('__session');
    const requestOptions = {
      "crossDomain": true,
      "method": "POST",
      "mode": "cors", // no-cors, cors, *same-origin
      "headers": {
        "content-type": "application/json; charset=utf-8",
        "cache-control": "no-cache",
        "Access-Control-Allow-Origin": "*",
        "Authorization": 'Bearer ' + jwt,
      },
      "credintials": "include",
      //"body": "password=" + password + "&username=" + username
      "body": JSON.stringify({ username: username, password: password })
    };

    // return {
    //     name: "Grogot",
    //     lastName: "Test",
    //     error: ""
    // }



    return fetch(`${process.env.REACT_APP_LOCAL_API_URL}api/User/Authenticate`, requestOptions)
      //return fetch('https://localhost:44383/User/authenticate', requestOptions)
      .then(this.handleResponse)
      .then(async user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('token', user.token);
        // localStorage.setItem('refreshToken', user.refreshToken);
        // let parsedToken = await this.parseJwt(user.token);

        // let ret = {
        //   UserId: parsedToken.UserId,
        //   Username: parsedToken.unique_name,
        //   Password: password,
        //   Token: user.access_token,
        //   LanguageId: parsedToken.LanguageId,
        //   TokenValidTime: moment(new Date()).add(10, 'm').format('YYYY-MM-DD HH:mm:ss'),
        //   KeepLoggedIn: true
        // }
        console.log("User token", user);
        Cookies.set('__session', user.token, { secure: true });

        fetch(`${process.env.REACT_APP_LOCAL_API_URL}api/FirmaInfo`, this.getRequestOption("GET", {}, false))
        .then(() => console.log("Subscribed to db changes."))
        .catch((err) => console.log("Subscription to db changes failed ", err));

        return user;
      })
      .catch(function (err) {
        let retVal = { error: "SERVICE_FAILED" }
        if(err !== "") retVal.error = err;
        return retVal;
      });
  }

  initialDataLoad = async () => {
    let dbNotifications = this.getAllNotifications();

    let res = [
      await dbNotifications
    ];

    return {
      notifications: res[0],
    }
  }

  async getAllNotifications() {
    let notifications = await this.db.table("notifications").toArray();
    let sorted = notifications.sort((a,b) => b.id - a.id);
    return sorted;
  }

  handleResponse = async response => {
    return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
          this.logout();
          // location.reload(true);
        }

        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }

      return data;
    });
  }

  getDataFromApi = async () => {
    //   const requestOptions = {
    //     //"crossDomain": true,
    //     "method": "GET",
    //     "mode": "cors", // no-cors, cors, *same-origin
    //     "headers": {
    //       "content-type": "application/json; charset=utf-8",
    //       // "cache-control": "no-cache",
    //       // "Access-Control-Allow-Origin": "*",
    //     },
    //     //"credintials": "include",
    //     //"body": "password=" + password + "&username=" + username
    //     //"body": JSON.stringify({  })
    //   };

    //   // return {
    //   //     name: "Grogot",
    //   //     lastName: "Test",
    //   //     error: ""
    //   // }

    //   return await fetch('https://localhost:5001/WeatherForecast', requestOptions)
    //     .then(res => res.json())
    //     .then(res => res);
    // }


    // let connection = new signalR.HubConnectionBuilder().withUrl("https://192.168.1.157:44393/Hubs/NotificationHub").build()

    // connection.start()

    // connection.on("refreshBannerText", function () {
    //   this.loadData()
    // })

    // this.loadData();
  }

  loadData = async () => {

    // $.ajax({
    //     url: 'http://localhost:53777/api/home',
    //     method: 'GET',
    //     success: (result)=>{
    //         $('body').append('<div>' + result + '<div>');
    //     },
    //     error: (error)=>{
    //         console.log(error)
    //     }
    // })

    const requestOptions = {
      //"crossDomain": true,
      "method": "GET",
      "mode": "cors", // no-cors, cors, *same-origin
      "headers": {
        "content-type": "application/json; charset=utf-8",
        // "cache-control": "no-cache",
        // "Access-Control-Allow-Origin": "*",
      },
      //"credintials": "include",
      //"body": "password=" + password + "&username=" + username
      //"body": JSON.stringify({  })
    };

    // return {
    //     name: "Grogot",
    //     lastName: "Test",
    //     error: ""
    // }

    return await fetch(`${process.env.REACT_APP_LOCAL_API_URL}api/home`, requestOptions)
      .then(res => res.json())
      .then(res => console.log(res));


  }

  getDataFromDb = async (url, data, settings) => {
    // const jwtToken = Cookies.get('__session');
    // let session = getSession();
    // console.log("session: ", session);
    let response = await fetch(`${process.env.REACT_APP_LOCAL_API_URL}${url}`, this.getRequestOption(settings.method, data, true))
    if (response.ok) {
      let res = await response.json();
      return res;
    }
    else{
      return this.tryToGetNewToken(response, () => this.getDataFromDb(url,data));
    }
  }

  async addNotification(data) {
    let id = await this.db.table("notifications").put({
      text: data.text,
      seen: 0,
      type: data.type,
      description: data.description,
      createdDate: new Date(),
      notificationForBeraterId: data.notificationForBeraterId
    });

    return { id, text: data.text, seen: false, type: data.type, createdDate: new Date(), description: data.description, notificationForBeraterId: data.notificationForBeraterId }
  }

  async markNotificationAsRead(id) {
    let deleteSeen = await this.db.table("notifications").where({"seen": 1}).delete();
    let notificationSuccess = await this.db.table("notifications").update(id, { seen: 1 });
    if (notificationSuccess) return this.db.table("notifications").toArray();

    return [];
  }

  async deleteNotification(id){
    let deleteS = await this.db.table("notifications").where({"id":id}).delete();
    //let notificationSuccess = await this.db.table("notifications").update(id, { seen: 1 });
    if (deleteS) return this.db.table("notifications").toArray();

    return [];
  }

  async GetCountFirmaInfo(){
    let cnt = await this.db.table("notifications").where({"text":"Firmen info","seen":0}).count();
    //let notificationSuccess = await this.db.table("notifications").update(id, { seen: 1 });
    

    return cnt;
  }

  _base64ToArrayBuffer(base64) {
    let _b64 = base64;
    if (_b64.startsWith("data:image/jpeg;base64,")) _b64 = _b64.substring(23);
    if (_b64.startsWith("data:image/png;base64,")) _b64 = _b64.substring(22);
    let raw = window.atob(_b64);
    let rawLength = raw.length;
    let array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return new Blob([array]);
  }

  async dataUpload(data) {
    let formData = new FormData();

    let blobData = data.Attachments.map(item => { return { blobData: this._base64ToArrayBuffer(item.data), fileName: item.fileName } });
    // this._base64ToArrayBuffer(data.Attachments);
    blobData.forEach(function (item) {
      formData.append("Attachments", item.blobData, item.fileName);
    });

    console.log("DATA:", data);
    // formData.append("Attachments[]", blobData);
    // data.map(item => {
    //   console.log("Data upload item : ", item);
    // })
    // formData.append("BeraterId", data.BeraterId);
    // formData.append("Subject", data.Subject);

    Object.keys(data).map(item => {
      console.log(item);
      if(item !== "Attachments" && item !== "endpoint"){
        formData.append(item, data[item]);
      }
      return item;
    });

    console.log("FORMDATA:", formData);

    //let jwtToken = this.getJwtToken();
    const requestOptions = {
      "crossDomain": true,
      "method": "POST",
      "mode": "cors", // no-cors, cors, *same-origin
      "headers": {
        "cache-control": "no-cache",
        "Access-Control-Allow-Origin": "*",
        //"Authorization": 'Bearer ' + jwtToken
      },
      //"credintials": "include",
      "body": formData
    };
    //+ "&grant_type=password"
    let response = await fetch(`${process.env.REACT_APP_LOCAL_API_URL}${data.endpoint}`, requestOptions);
    if (response.ok) {
      let res = await response.json();
      return res;
    } else return false;

  }

  tryToGetNewToken = async (response, getDataAgain) => {
    // let jwtToken = this.getJwtToken();
    const jwtToken = Cookies.get('__session');
    if (response.status === 401 && response.headers.has('Token-Expired')) {
      logOut();
      //flag = true;

      // var refreshToken = this.getRefreshToken();

      // var refreshResponse = await this.refresh(jwtToken, refreshToken);
      // if (!refreshResponse.ok) {
      //   return response; //failed to refresh so return original 401 response
      // }
      // var jsonRefreshResponse = await refreshResponse.json(); //read the json with the new tokens

      // this.saveJwtToken(jsonRefreshResponse.token);
      // this.saveRefreshToken(jsonRefreshResponse.refreshToken);
      // return await getDataAgain(); //repeat the original request
    }
    // else if (response.status === 401 && flag === false) {
    //   //console.log("Flag :");
    //   localStorage.removeItem("token");
    //   window.location.reload();
    // }
    else { //status is not 401 and/or there's no Token-Expired header
      localStorage.removeItem("token");
      window.location.reload();
      //return response; //return the original 401 response
    }
  }

}