import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import DbTasks from '../../db/DbTasks';
import {  GridListTile, GridListTileBar, IconButton, makeStyles } from '@material-ui/core';
import BackdropSpinner from '../Reusable/BackdropSpinner';
import {  FaFolderOpen, FaImage } from 'react-icons/fa';
import {getDataFromDatabase} from '../../db/api';
import MitarbeiterGallery from './MitarbeiterGallery';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(2),
  },
  cardRoot: {
    minWidth: 245,
    height: 245,
    listStyleType: 'none'
  },
  gridList: {
    width: 'auto',
    height: 'auto',
    textAlign: 'left',
    margin: theme.spacing(2)
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  cardText: {
    textAlign: 'left'
  },
  cardIcon:{
    width: "100%", 
    height: "100%", 
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  }
}));

function Gallery(props) {
  const classes = useStyles();
  const [folders, setFolders] = useState([]);
  const [showLoading, setLoading] = useState(true);

  useEffect(() => {
    //let week = parseInt(props.match.params.id);    
    getDataFromDatabase("api/Gallery/GetGalleryFolders", {}, { method: "GET" })
      .then(res => {
        setFolders(res);
        setLoading(false);
      })

    return () => {
      console.log("Running cleanup function !");
    }
  }, [])

  return (
    <>
    {/* <MitarbeiterGallery/> */}
    <div className={classes.root}>
      {/* Gallery */}
      <Grid container spacing={1} alignContent='center' justify='center' data-testid="content" >
        {folders && folders.map((el) => (
          <Grid item lg={3} md={4} sm={6} xs={12} key={el.id}>
            <GridListTile key={el.id} className={classes.cardRoot}>
              {el.base64 !== null ? <img src={`data:image/${el.extension};base64,${el.base64}`} style={{ width: "100%" }} alt={"Gallery"} /> :
                <div className={classes.cardIcon} data-testid={`optional-div-${el.id}`}><FaImage size={"5em"} color="grey" /></div>}

              <GridListTileBar
                className={classes.cardText}
                title={el.folderName}
                subtitle={<span>{el.description}</span>}
                key={el.id}
                actionIcon={
                  <div>
                    <IconButton onClick={() => props.history.push("/gallery/" + el.id)} className={classes.icon} data-testid={`icon-button-${el.id}`}>
                      <FaFolderOpen size={"2em"} color="white" />
                    </IconButton>
                  </div>
                }
              />
            </GridListTile>
          </Grid>
        ))}
        <Grid item lg={3} md={4} sm={6} xs={12}>
            <GridListTile className={classes.cardRoot}>
              {null !== null ? <img src={``} style={{ width: "100%" }} alt={"Gallery"} /> :
                <div className={classes.cardIcon}><FaImage size={"5em"} color="grey" /></div>}

              <GridListTileBar
                className={classes.cardText}
                title={"Fotos hochgeladen von Mitarbeiter"}
                // subtitle={<span>{el.description}</span>}
                key={"mitarbeiter fotos"}
                actionIcon={
                  <div>
                    <IconButton onClick={() => props.history.push("/mitarbeitergallery")} className={classes.icon}>
                      <FaFolderOpen size={"2em"} color="white" />
                    </IconButton>
                  </div>
                }
              />
            </GridListTile>
          </Grid>
      </Grid>
      <BackdropSpinner showLoading={showLoading} />
    </div>
    </>
  )
}

export default withRouter(Gallery);