import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    //display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    //margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function Spinner(props) {
  const classes = useStyles();

  let loading = useSelector(state => state.app.buttonLoadingEnabled);


  const handleButtonClick = () => {
    props.handleSubmit();
  };

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Button
          variant="contained"
          color="primary"
          //className={props.class}
          disabled={loading}
          onClick={handleButtonClick}
          className={props.customStyle} 
          fullWidth={props.fullWidth} 
          data-testid="button"        
        >
          {props.buttonText}
        </Button>
        {loading && <CircularProgress size={24} className={classes.buttonProgress} data-testid="progress"/>}
      </div>
    </div>
  );
}
