import React, { useEffect } from 'react';
import './App.css';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import Login from './components/Login';
import { PrivateRoute } from './components/PrivateRoute';
//import Home from './components/Home';
import MainLayout from './components/MainLayout';
import { useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
      flexGrow: 1,
      margin: theme.spacing(2),
  },
  allgemein:{
      // backgroundColor: 'red',
      margin: '5px',
      borderTop: '1px solid #1976d2',
      textAlign: 'center',
      display:'block'
      //display: 'none'
  },
  allgemeinNoContent: {
      display: 'none'
  },
  show: {
    display: 'block',
    position: 'fixed',
    bottom: '0px',
    width: '100%',
    backgroundColor: '#ffffff',
    borderTop: '2px solid #1976d2',
    marginBottom: '5px',
    paddingTop: '5px',
  },
  hide: {
    display: 'none',
  },
  promptBar: {
    position: 'absolute',
    bottom: '0px',
    width: '100%',
    backgroundColor: '#1976d2',
  },
  installText: {
    verticalAlign: 'top',
    marginRight: '10px',
    fontSize: '20px',
  }
}));

function App() {
  const theme = createMuiTheme({  shadows: Array(25).fill("none"),
  typography: {
      useNextVariants: true,
      color: "#444"
  },
  palette: {
      primary: {
          main: "#1976d2",
          contrastText: "#FFFFFF",
      },
      secondary: {
          main: "#dc004e",
          contrastText: "#FFFFFF"
      }
  },

});
  const [showInstallPrompt, setInstallPrompt] = useState(false);
  const [deferredPrompt, setDeferred] = useState(null);
  const classes = useStyles();

  const hideInstallPrompt = () => {
    // this.setState({InstallPrompt: false});
    // this.props.InstallPrompt(true);
    setInstallPrompt(false);
  }

  const addToHomeScreen = () => {
    // this.setState({ InstallPrompt: false });
    // this.props.InstallPrompt(true);
    setInstallPrompt(false);
    deferredPrompt.prompt();  // Wait for the user to respond to the prompt
    deferredPrompt.userChoice
      .then((choiceResult) => {

        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the prompt');
        } else {
          console.log('User dismissed the prompt');
        }

        setDeferred(null);
        //this.deferredPrompt = null;

      });
  }

  useEffect(() => {
      //console.log("Listening for Install prompt");
      window.addEventListener('beforeinstallprompt', (e) => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        setDeferred(e);
        console.log("Deffered prompt : ", e);
        //alert("Before install .");
        //this.setState({ InstallPropmt: true });
        //if(!this.props.app.alreadyInstalled)  this.setState({ InstallPrompt: true });
        if (!window.matchMedia('(display-mode: standalone)').matches) {
          setInstallPrompt(true);
          console.log('display-mode is standalone');
        }

      });
    return () => {
        console.log("Running cleanup function !");
    }
}, [])

  const routes = ["/home", "/notifications", "/urlaubDiesesJahr","/urlaubNaechstesJahr" ,'/urlauboverview', '/urlauboverviewplanned', "/krankenmeldung", "/tagesleistung/:id/:year", "/tagesleistung/:id", "/monatsleistung", "/gallery", "/gallery/:id", "/kvp", "/wegparent/:id",
"/wegweisser/:id", "/wegweisseranswer/:id", "/allgemein", "/veranstaltungen", "/arbeitszeit", "/mitarbeiterhandbuch", "/zeiterfassung", "/chatlist", "/chat/:id","/groupchat/:groupId", "/veranstaltungen/:id", "/mitarbeitergallery","/mitarbeitergallery/:id","/mitarbeitergallerypreview/:folderId/:id", "/changepassword"];
  //# B2E477
  return (
    <MuiThemeProvider theme = {theme}>
      <div className="App" id="appdiv">
        <Router>
          <Switch>
          <Route exact path="/" component={Login} />
          {routes.map(route => <PrivateRoute key={route} exact path={route} component={MainLayout} />)}
          </Switch>
        </Router>
        <div
            className={
              showInstallPrompt ? classes.show : classes.hide
            }
          >
            <span className={classes.installText}>App installieren ?</span>
            <Button
              style={{ marginRight: '10px' }}
              variant="outlined"
              color="primary"
              size="small"
              //onClick={() => this.setState({ InstallPropmt: false })}
              onClick={() => hideInstallPrompt()}
            >
              Nein
            </Button>
            <Button
              //style={{ backgroundColor: "#37a58c" }}
              variant="contained"
              color="primary"
              size="small"
              onClick={() => addToHomeScreen()}>
              Ja
                                    </Button>
          </div>
      </div>
    </MuiThemeProvider>
  );
}

export default App;