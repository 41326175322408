import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import DbTasks from '../../db/DbTasks';
import moment from 'moment-with-locales-es6';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CardContainer from '../Reusable/CardContainer';
import { FaEuroSign } from 'react-icons/fa';
import { getBeraterId } from '../../helpers/HelperFunctions';
import BackdropSpinner from '../Reusable/BackdropSpinner';
import {getDataFromDatabase} from '../../db/api';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(2),
        //fontFamily: 'sans-serif',
    },
    cardRoot: {
        minWidth: 245,
        height: 245,
        margin: theme.spacing(2)
    },
    paper: {
        padding: theme.spacing(0),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        //backgroundColor: '#ffffa7'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 20,
    },
    pos: {
        marginBottom: 12,
    },
    green: {
        color: 'green'
    },
    red: {
        color: 'red'
    }
}));


function Tagesleistung(props) {
    const classes = useStyles();
    const [premieState, setPremieData] = useState({
        tagesData: [],
        minPremia: 0,
        maxPremia: 0,
        correction:0,
    });
    const [showLoading, setLoading] = useState(true);

    useEffect(() => {
        let month = parseInt(props.match.params.id);
        let year = parseInt(props.match.params.year);
        let beraterId = getBeraterId();
        getDataFromDatabase("api/Leistungspremie/GetWochePremie", { month: month, year: year, beraterId: beraterId }, { method: "POST" })
            .then(res => {
                //console.log("Tagesleistung, :", res);
                setPremieData({...premieState, tagesData: res.data, minPremia: res.minPremia, maxPremia: res.maxPremia, correction:res.correction });
                setLoading(false);
            }).catch(err => {
                setLoading(false);
                alert("Service not available");
            })
        return () => {
            console.log("Running cleanup function !");
        }
    }, [])

    // const cardDetails = (data) => {
    //     return (
    //         <Card className={classes.cardRoot} variant="outlined">
    //             <CardContent>
    //                 <Typography className={classes.title} color="textSecondary" gutterBottom>
    //                     {data.title}
    //                 </Typography>
    //                 <Typography variant="h1" component="p" className={classes[data.class || ""]}>
    //                     {data.number}
    //                 </Typography>
    //                 <Typography className={classes.pos} color="textSecondary">
    //                     premia
    //     </Typography>
    //                 {/* <Typography variant="body2" component="p">
    //                     well meaning and kindly.
    //       <br />
    //                     {'"a benevolent smile"'}
    //                 </Typography> */}
    //             </CardContent>
    //             {/* <CardActions>
    //                 <Button size="small">Learn More</Button>
    //             </CardActions> */}
    //         </Card>
    //     )
    // }


    return (
        <div className={classes.root}>
            <Grid container spacing={1} alignContent='center' justify='center' style={premieState.tagesData.length === 0 ? {flexDirection: "column"} : {flexDirection: "row"} }>
                <Grid item lg={3} md={4} sm={6} xs={12} style={premieState.tagesData.length === 0 ? {width: "465px"} : {width: "initial"}}>
                    <Paper className={classes.paper}>
                        <CardContainer
                            title={`Korrektur prämien`}
                            number={premieState.correction === null ? 0 : premieState.correction}
                            subtitle={premieState.tagesData[0] ? moment(premieState.tagesData[0].date).locale('de').format('MMMM') : ""}
                            class={"red"}
                            mainIcon={<FaEuroSign size={"4em"} color="white" />}
                        />

                        </Paper>
                    </Grid>
                {premieState.tagesData && premieState.tagesData.map((el, index) => {
                    //   return  <li key={el.weekNr}>{el.weekNr}</li>
                    return <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
                        <Paper className={classes.paper}>
                            <CardContainer
                                title={`${el.date !== null ? moment(el.date).format("DD.MM.YYYY") : ""}`}
                                number={el.premia}
                                subtitle={"Tagesprämie"}
                                class={premieState.minPremia === el.premia ? "red" : premieState.maxPremia === el.premia ? "green" : "blue"}
                                mainIcon={<FaEuroSign size={"4em"} color="white" />}
                            />

                        </Paper>
                    </Grid>
                })}
                {premieState.tagesData.length === 0 && <p>Es sind keine Daten für den Vormonat vorhanden</p>}
                <Grid item lg={12} sm={12} xs={12}>

                </Grid>
            </Grid>
            <BackdropSpinner showLoading={showLoading} />
        </div>
    )
}

export default withRouter(Tagesleistung);