import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getBeraterId } from '../../helpers/HelperFunctions';
import DbTasks from '../../db/DbTasks';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment-with-locales-es6';
import { FaBusinessTime } from 'react-icons/fa';
import { FaSignInAlt } from 'react-icons/fa';
import { FaSignOutAlt } from 'react-icons/fa';
import { FaCalendarDay } from 'react-icons/fa';
import { FaUserClock } from 'react-icons/fa';
import { Grid } from '@material-ui/core';
import BackdropSpinner from '../Reusable/BackdropSpinner';
import {handleUrlaubIcon} from '../../helpers/HelperFunctions'
import { getDataFromDatabase } from '../../db/api';
import {Select} from '@material-ui/core';
import {MenuItem} from '@material-ui/core';

//icons
import urbaub_icon from "../../../src/assets/icons/add_urlaub.png";
import add_feiertag from "../../../src/assets/icons/add_feiertag.png";
import add_krank from "../../../src/assets/icons/add_krank.png";
import uberstunden from "../../../src/assets/icons/uberstunden.png";
import abwesend from "../../../src/assets/icons/abwesend.png";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  center:{
    fontWeight:"bold", 
    textAlign:"center"
  },
  left:{
    fontWeight:"bold", 
    textAlign:"left",
    marginRight:"300px"
  },
  bold:{
    fontWeight:'bold'
  },
  border:{
    border:'1px solid blue'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  table: {
    minWidth: 350,
    maxWidth: 500,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  accordionBorder: {
    borderBottom: '1px solid #1976d2',
  },
  accordionSummary: {
    '@media only screen and (max-width: 600px)': {
      display: 'block',
    },
    '@media only screen and (min-width: 601px)': {
      display: 'inline-block',
    }
  },
  accordionSummaryParent: {
    textAlign: 'left'
  },
  redBoldText:{
    color:"red",
    fontWeight:"bold"
  }
}));

export default function Zeiterfassung() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [year,setYear] = useState(new Date().getFullYear())

  useEffect(() => {
    setLoading(true);
    let beraterId = getBeraterId();
    getDataFromDatabase(`api/FirmaInfo/Zeiterfassung`,
      { BeraterId: beraterId, year:year },     
      { method: "POST" })
      .then(res => {
        console.log("resztf",res)
        setData(res.monthsData);
        setLoading(false);
      })
      .catch((error) => setLoading(false))
    return () => {
      console.log("Running cleanup function !");
    }
  }, [])

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleYearChange = (e) =>{
    setYear(e.target.value);
    setLoading(true);
    let beraterId = getBeraterId();
    getDataFromDatabase(`api/FirmaInfo/Zeiterfassung`,
      { BeraterId: beraterId, year:e.target.value },     
      { method: "POST" })
      .then(res => {
        console.log("resztf",res)
        setData(res.monthsData);
        setLoading(false);
      })
      .catch((error) => setLoading(false))
  }

  const generateTable = (data) => {
    return <TableContainer component={Paper} key={"tabledata" + data.month}>
      <Table className={classes.table} size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell><FaCalendarDay size={20} /></TableCell>
            <TableCell></TableCell>
            <TableCell align="right"><FaSignInAlt size={20} /></TableCell>
            <TableCell align="right"><FaSignOutAlt size={20} /></TableCell>
            {/* Uhrzeit gegangen */}
            <TableCell align="right"><FaBusinessTime size={20} /></TableCell>
            {/* Uberstunden */}
            <TableCell align="right"><FaUserClock size={20} /></TableCell>
            {/* Uberstunden insgesamt */}
            <TableCell align="right"><FaUserClock size={20} /></TableCell>
            {/* Arbeitszeit insgesamt */}
            <TableCell align="right"><FaUserClock size={20} /></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {row.date ? moment(row.date).locale("de").format("dddd DD.MM") : ""}
              </TableCell>
              <TableCell className={classes.redBoldText}><img src={handleUrlaubIcon(row.type, row.urlaub_Type, row.urlaub_Subtype)}/>{index==0 || index+1 == data.length ? "Übertrag":""}</TableCell>
              <TableCell align="right">{row.uhrezeitKommt ? moment(row.uhrezeitKommt).format("HH:mm") : ""}</TableCell>
              <TableCell align="right">{row.uhrezeitGeht ? moment(row.uhrezeitGeht).format("HH:mm") : ""}</TableCell>
              <TableCell align="right">{row.bruttoT}</TableCell>
              <TableCell align="right">{row.difBrT}</TableCell>
              <TableCell align="right">{row.saldoT}</TableCell>
              <TableCell align="right">{row.bruttoPeT}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  }

  const generateLegend = () => {
    return <TableContainer component={Paper} key={"tabledata" + data.month}>
      <Table className={classes.table} size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell  className={classes.left}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={year}
              label="Jahre"
              onChange={(e)=>handleYearChange(e)}
            >
            <MenuItem value={new Date().getFullYear()}>{new Date().getFullYear()}</MenuItem>
            <MenuItem value={new Date().getFullYear()-1}>{new Date().getFullYear()-1}</MenuItem>
            </Select>
            </TableCell>
            <TableCell className={classes.center} >Legende:</TableCell>
            <TableCell className={classes.center} style={{paddingBottom:'4px'}} align="right"><img src={add_feiertag}/>{" Feiertag"}</TableCell>
            {/* Uhrzeit gegangen */}
            <TableCell className={classes.center} align="right"><img src={abwesend}/>{" Abwesend"}</TableCell>
            {/* Uberstunden */}
            <TableCell className={classes.center} align="right"><img src={urbaub_icon}/>{" Urlaub"}</TableCell>
            {/* Uberstunden insgesamt */}
            <TableCell className={classes.center} align="right"><img src={uberstunden}/>{" Überstunden"}</TableCell>
            {/* Arbeitszeit insgesamt */}
            <TableCell className={classes.center} align="right"><img src={add_krank}/>{" Krank"}</TableCell>
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  }

  const generateAccordion = (data) => {

    return (
      <Accordion key={"accordion" + data.month} expanded={expanded === `panel${data.month}`} onChange={handleChange(`panel${data.month}`)} data-testid={`panel-${data.month}`}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className={classes.accordionBorder}
        >
          {/* <div className={classes.accordionSummaryParent}> */}
            {/* <span className={classes.accordionSummary}>{data.month}</span> */}
            <Grid container spacing={0} justify='flex-start' alignContent='center' className={classes.accordionSummaryParent}>
              <Grid item xs={12} sm={12} md={4}>
                <span >{data.month}</span>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <span >Uberstunden insgesamt: <b>{data.monthSaldo}</b></span>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <span >Arbeitszeit insgesamt: <b>{data.previousMonthArbeitzeit}</b></span>
              </Grid>
            </Grid>
        </AccordionSummary>
        <AccordionDetails style={{ textAlign: 'center' }}>
          {data.dailyData && generateTable(data.dailyData)}
        </AccordionDetails>
      </Accordion>
    )
  }

  return (
    <div className={classes.root}>
      {generateLegend()}
      {data && data.map(item => generateAccordion(item))}
      {data && data.length === 0 && <p>There is no data available at the moment.</p>}
      <BackdropSpinner showLoading={loading} />
    </div>
  );
}
