import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CustomTextField from './Reusable/TextFields';
// import style from '../assets/styles/Login.module.css';
import { userLogin } from '../actions/UserActions';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import clsx from 'clsx';
import { getSession } from '../helpers/HelperFunctions';
import DbTasks from "../db/DbTasks";
import { addNewNotification } from '../actions/NotificationActions';
import { useDispatch } from "react-redux";
import { getBeraterId } from "../helpers/HelperFunctions";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '70vh',
        maxWidth: '100%',
        overflowX: 'hidden',
        margin: theme.spacing(0),

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

    },
    elementSpacing: {
        margin: theme.spacing(1)
    },
    button: {
        backgroundColor: "#166abd",
        '&:hover': {
            backgroundColor: "#166abd",
        },
        color: "#FFFFFF",
        fontSize: "22px",
        margin: theme.spacing(1)
    },
    wrapper: {
        margin: theme.spacing(0),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: "#3cb498",
        '&:hover': {
            backgroundColor: "#0eb58e",
        },
        color: "#FFFFFF",
        fontSize: "22px",
        margin: theme.spacing(1)
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    logo:{
        maxHeight: '100%',
        maxWidth: '100%',
    },
    incorrectCredentials:{
        color: 'red',
    }
}));

const Login = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [local, setLocal] = React.useState({
        success: false,
        loading: false,
        error: false,
        errorType:0
    })
    let [benutzer, setName] = useState("");
    let [passwort, setPasswort] = useState("");
    //const timer = React.useRef();

    React.useEffect(() => {
        getSession() ? props.history.push("/home") : console.log("User not logged in.");
        // return () => {
        //     clearTimeout(timer.current);
        // };
    }, []);

    const buttonClassname = clsx({
        [classes.buttonSuccess]: local.success,
        [classes.button]: !local.success
    });


    // const setChatNotifiactionsOnLogin = async() => {
    //     let beraterId = getBeraterId();
    //     await new DbTasks().getDataFromDb(`api/Chat/getMessagesForNotifications?loggedInUserId=${beraterId}`, {}, { method: "GET" })
    //         .then(res => {
    //             if (res)
    //             {
    //                 res.forEach(e=>{
    //                     let data = {
    //                         text: `You have new message!`,
    //                         description: "",
    //                         type: e.idCreator,
    //                     }
    //                     dispatch(addNewNotification(data));
    //                 })
    //             }
    //         });
    // }

    const logIn = async (localProps) => {
        if (!local.loading) {
            setLocal({...local, success: false, loading: true});
            // timer.current = setTimeout(() => {
            //     setSuccess(true);
            //     setLoading(false);
            // }, 2000);
        }
        //console.log("Login this :", this);
        if (navigator.onLine === false) {
            alert("You're offline. Service unavailable !");
            return;
        }

        let loginReturn = await userLogin(benutzer, passwort, null);

        console.log("login return", loginReturn);
        if(!loginReturn) {
            setLocal({...local, success: false, loading: false, error: true, errorType:0}); //errorType = 0 means that username or password are incorrect
        }
        else if(loginReturn.message != null)
        {
            setLocal({...local, success: false, loading: false, error: true, errorType:1}); //errorType = 1 means that user is inactive
        }
        else {
            setLocal({...local, success: true, loading: false, error: false});
            localProps.history.push("/home");
        }
    }

    return (
        <div className={classes.root}>
            <Grid container  direction="column" spacing={0} alignContent='center' justify='center' alignItems='center'>
                <Grid item lg={4} md={6} sm={12} xs={10}>
                    {/* <img src={Logo} className={style.logo} alt="Logo" /> */}
                    {/* <div style={{ height: '100%' }}> */}
                        <div style={{ textAlign: 'center' }}>
                            <img src="/mitarbeiterLogo.png" alt="Window-ID" className={classes.logo} />
                        </div>
                    {/* </div> */}
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={10}>
                    <CustomTextField
                        onChange={(e) => setName(benutzer = e.currentTarget.value)}
                        value={benutzer}
                        id="benutzer"
                        type="text"
                        label="Benutzer"
                        variant="outlined"
                        fullWidth={true}
                        class={classes.elementSpacing}
                    // class={style.margin}
                    />

                    <CustomTextField
                        onChange={(e) => setPasswort(passwort = e.currentTarget.value)}
                        value={passwort}
                        id="password"
                        type="password"
                        label="Passwort"
                        variant="outlined"
                        fullWidth={true}
                        //error={true}
                        //helperText={"Wrong pass or username"}
                        onKeyPress={(e)  => {
                            if(e.key === "Enter"){
                                logIn(props);
                            }
                        }}
                        class={classes.elementSpacing}
                    // style={style.textField}
                    />
                    <div>
                    {local.error == true && local.errorType == 0 ? <span className={classes.incorrectCredentials}>falsche Anmeldeinformationen</span> : local.error == true && local.errorType == 1 ? <span className={classes.incorrectCredentials}>Der Mitarbeiter ist nicht aktiviert</span> : "" }
                    </div>

                    {/* <div className={classes.root}> */}
                    <div className={classes.wrapper}>
                        <Button
                            disabled={local.loading}
                            data-testid="loginId"
                            onClick={() => logIn(props)}
                            className={buttonClassname}
                            //className={style.loginBtn}
                            variant="contained" fullWidth >
                            LOGIN
                        </Button>
                        {local.loading && <CircularProgress size={30} className={classes.buttonProgress} />}
                    </div>
                    {/* </div> */}
                </Grid>
            </Grid>
        </div>
    )
}


export default Login;
